import React from 'react'

import { useQuery } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'
import type { ApolloError } from '@apollo/client'

import { ShippingOptionsDocument } from '../graphql/__generated__'
import type { DestinationFragment } from '../graphql/__generated__'

type ShippingChoiceProps = {
  destination: DestinationFragment
}

export default function ShippingChoice({ destination }: ShippingChoiceProps) {
  const { loading, error, data } = useQuery(ShippingOptionsDocument, {
    variables: {
      destinationId: destination.id,
    },
    onError: (error: ApolloError) => {
      console.error("Error loading shipping options for display", error)
    },
  })

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <div className="p-2 bg-red-200">Error loading shipping rates. Please confirm that the address is valid.</div>
  }

  let option = data.shippingOptions.find((option: any) => option.shippingSpeed === destination.shippingSpeed || option.shippingServiceCode === destination.shippingServiceCode)

  if (!option) {
    return <p className="p-2 bg-gray-200">No shipping option selected.</p>
  }

  return <p>{ option.displayName }</p>
}
