import React from 'react'

import { formatDate } from '../utils/Date'
import type { OrderFragment } from '../graphql/__generated__'

type OrderProgressBarProps = {
  order: OrderFragment
}

const stateLabels: {[key: string]: string} = {
  'draft': 'Created',
  'paid': 'Placed',
  'completed': 'Shipped',
  'archived': 'Archived',
  'canceled': 'Canceled',
}

const orderedStates = ['draft', 'paid', 'completed']

export default function OrderProgressBar({ order }: OrderProgressBarProps) {
  const state: string = order.state

  const dateMap: {[key: string]: string} = {
    'draft': order.createdAt,
    'paid': order.paidAt,
    'completed': order.finalizedAt,
    'canceled': order.cancelledAt,
  }
  const date = dateMap[state]
  const step = orderedStates.indexOf(state)

  return <div className="border-t border-gray-200 py-6 px-4 sm:px-6 lg:p-8">
    <h4 className="sr-only">Status</h4>
    <p className="text-sm font-medium text-gray-900">
      { stateLabels[state] }
      { date && <>
          &nbsp;on <time dateTime={ date }>{ formatDate(date) }</time>
        </>
      }
    </p>
    <div className="mt-6" aria-hidden="true">
      <div className="bg-gray-200 rounded-full overflow-hidden">
        <div
          className="h-2 bg-indigo-600 rounded-full"
          style={{ width: `calc((${ step } * 2 + 1) / 8 * 100%)` }}
        />
      </div>
      <div className="hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600 mt-6">
        <div className="text-center text-indigo-600">Order Placed</div>
        <div className={"text-center " + (step > 0 ? 'text-indigo-600' : '')}>
          Processing
        </div>
        <div className={"text-center " + (step > 1 ? 'text-indigo-600' : '')}>
          Shipped
        </div>
        <div className={"text-center " + (step > 2 ? 'text-indigo-600' : '')}>
          Delivered
        </div>
      </div>
    </div>
  </div>
}
