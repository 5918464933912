import React from 'react'

import type { CustomerBalanceProductFragment, Maybe, OrderFragment } from '../graphql/__generated__'

type OrderBalanceUsageProps = {
  order: Maybe<OrderFragment>
}

export default function OrderBalanceUsage({ order }: OrderBalanceUsageProps) {
  const cost = order?.cost?.total
  const bookProduct: CustomerBalanceProductFragment|undefined = cost?.balanceUsed?.products.find(p => p.product.type === 'player')

  if (!bookProduct) {
    return <></>
  }

  let title = "Books purchased on "
  let parts: Set<string> = new Set(bookProduct.entries.map((e) => {
    return `${ new Date(e.createdAt).toLocaleDateString() } from ${ e.vendorDisplayName }`
  }))

  title += [...parts].join(', ')

  return <p className="text-center text-gray-500" title={ title }>
    Using { bookProduct.quantity } of your prepurchased books.
  </p>
}
