import React, { useEffect } from 'react'
import type { RefObject } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'

import { DesignFragment } from '../graphql/__generated__'
import MediaManager from '../components/MediaManager'
import NewDesignOptions from '../components/NewDesignOptions'
import LoginModal from '../components/LoginModal'
import ButtonLink from '../components/ButtonLink'
import { useQueryParams } from '../utils/Query'
import useSteps, { LocalFlow, BuilderFlow, MediaStep } from '../utils/Steps'

type DesignEditorProps = {
  dropContainer: RefObject<HTMLDivElement>
}

export default function DesignEditor({ dropContainer }: DesignEditorProps) {
  // We collect the destinationId here, even if designs are the same for all
  // their destinations. We do have a URL pattern which supports destination id 
  // to allow us to navigate through steps remembering which destination sent
  // us so we can navigate to other pages specific to that destination.
  const { orderId, designId, destinationId } = useParams()
  const navigate = useNavigate()
  const params = useQueryParams()
  const { currentPath, nextPath, hasReturn } = useSteps(
    // This page is used both by the local flow, which customers use to load books they already
    // have, and the builder flow which is used to design books which are mailed to them fully
    // loaded. In the local flow there won't be an order id, as those designs aren't associated
    // with an order.
    (orderId ? BuilderFlow : LocalFlow),
    MediaStep,
    { orderId, designId, destinationId },
  )

  return <>
    { !designId && orderId &&
      <NewDesignOptions
        orderId={ orderId }
        onCreate={ (design: DesignFragment) => {
          navigate(currentPath({ designId: design.id }))
        }}
      /> }

    { designId && <>
      <MediaManager
        designId={ designId }
        dropContainer={ dropContainer }
      />

      <div className="grid justify-items-end">
        <ButtonLink
          type="primary"
          to={ nextPath({}) || '' }
        >{ hasReturn ? 'Done' : 'Next' }</ButtonLink>
      </div>
    </> }

    <LoginModal />
  </>
}
