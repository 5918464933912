import React, { useState } from 'react'
import { useDraggable } from '@dnd-kit/core'
import CircularProgress from '@mui/material/CircularProgress'

import Button from './Button'
import ModalMediaEditor from './ModalMediaEditor'
import type { MediaEntryFragment } from '../graphql/__generated__'

type SortableMediaViewerProps = {
  media: MediaEntryFragment
  allMedia?: MediaEntryFragment[]
  onRemove?: () => void
  showIcons?: boolean
  playable?: boolean
  designId: string
}

export default function SortableMediaViewer({ media, designId, onRemove, showIcons, playable, allMedia }: SortableMediaViewerProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
  } = useDraggable({id: media.id})

  const [showEditor, setShowEditor] = useState<boolean>(false)
  const [showRemove, setShowRemove] = useState<boolean>(false)

  let spinner = <></>
  if (media.encodeProgress < 100) {
    spinner = (
      <div className="absolute inset-0 flex justify-items-center items-center">
        <div className="relative w-full bg-white/90 p-1 font-bold text-gray-800
                        rounded-full border text-xs h-7 overflow-hidden mx-2">
          <div className="absolute left-0 top-0 bottom-0 bg-blue-200/50 z-10"
               style={{ right: (100 - media.encodeProgress) + '%' }}
          />
          <div className="absolute z-20 inset-0 leading-6 text-center [text-shadow:0_0_2px_white]">Processing File...</div>
        </div>
      </div>
    )
  }

  return <>
    <div
      className="w-[10rem] h-[10rem] relative flex flex-col bg-gray-50
                 shadow-lg cursor-move rounded overflow-hidden"
      ref={setNodeRef} {...attributes} {...listeners}
    >

      { /* TODO it would be nice to use flexbox to set the height, rather than our calc hack */ }
      <div
        className="flex items-center justify-center text-pink-300"
        style={{ height: 'calc(100% - 34px)' }}>
      { media.signedVideoThumbnailLocation && playable !== false
        ?
          <video
            className="max-w-full m-auto max-h-full"
            playsInline
            muted
            poster={ media.signedThumbnailLocation || "" }
            src={ media.signedVideoThumbnailLocation || "" }
            onMouseOver={ (e) => { media.signedVideoThumbnailLocation && e.currentTarget.play() }}
            onMouseLeave={ (e) => { e.currentTarget.pause(); e.currentTarget.currentTime = 0 }}
          />
        :
          (media.signedThumbnailLocation &&
            <img className="max-w-full m-auto max-h-full"
              src={ media.signedThumbnailLocation || "" }
              alt="Thumbnail of video / photo"
            />
          )
      }
      </div>

      { showIcons !== false &&
        <div className="w-full bg-pink-300 text-white grid grid-cols-3 leading-none p-1 pt-[0.325rem] h-[34px]">
          { media.type === 'video'
            ? <div>
                <button
                  onClick={ () => setShowEditor(true) }
                  title={ media.signedEncodedLocation ? "Edit video" : "Video still being processed..." }
                  className="hover:text-gray-100"
                  disabled={ !media.signedEncodedLocation }
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                  </svg>
                </button>
              </div>
            : <div></div>
          }

          <div className="justify-self-center mt-[0.125rem] hover:text-gray-100" title="Reorder photo / video">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
            </svg>
          </div>

          { onRemove && !showRemove &&
            <div className="justify-self-end">
              <button
                onClick={ () => setShowRemove(true) }
                title={ "Remove video / photo" }
                className="hover:text-gray-100"
                >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          }
          { onRemove && showRemove &&
            <div className="absolute top-0 right-0 bottom-0 left-0 backdrop-blur">
              <div className="flex flex-col justify-center items-center gap-2 h-full text-sm">
                <Button type="danger" onClick={ () => onRemove() } className="w-24">
                  Remove
                </Button>
                <Button onClick={ () => setShowRemove(false) } className="w-24">
                  Cancel
                </Button>
              </div>
            </div>
          }


        </div>
        }

      <div className="absolute top-0 left-0 w-[10rem] h-[10rem] flex pointer-events-none">
        <div className="m-auto">
          { spinner }
        </div>
      </div>
    </div>

    { media.type === 'video' &&
      <ModalMediaEditor
        designId={ designId }
        media={ media }
        show={ showEditor }
        onClose={ () => setShowEditor(false) }
        allMedia={ allMedia }
      />
    }
  </>
}
