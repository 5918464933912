import React, { useState } from 'react'
import type { DesignFragment, DestinationFragment, Maybe, OrderFragment } from "../graphql/__generated__"

import DestinationSummary from './DestinationSummary'
import MediaStack from './MediaStack'
import ModalPreview from './ModalPreview'
import ButtonLink from './ButtonLink'
import Button from './Button'
import { formatDate } from '../utils/Date'
import { formatDuration } from '../utils/Duration'
import useSteps, { AddressStep, MediaStep } from '../utils/Steps'

type DesignSummaryProps = {
  order: OrderFragment
  design: DesignFragment
  destinations: Maybe<DestinationFragment[]>
  allowEdit: boolean
  shrink?: boolean
}

export default function DesignSummary({ order, design, destinations, allowEdit, shrink }: DesignSummaryProps) {
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [shrunk, setShrunk] = useState<boolean>(shrink || false)
  const { navigateTo } = useSteps()

  if (!design || !order) {
    return <></>
  }

  return (
    <div>
      <ModalPreview
        show={ showPreview }
        onClose={ () => setShowPreview(false) }
        designId={ design.id }
        generation={ design.generation }
      />

      <header>
        <div className="grid grid-cols-12 md:pt-5 pb-8">
          <div className="hidden md:block col-span-9">
            <MediaStack media={ design.media || null } />
          </div>
          <div className="col-span-12 md:col-span-3">
            <Button
              className="m-auto text-sm block my-2 w-48"
              type="primary"
              onClick={ () => setShowPreview(true) }>Watch Preview</Button>
            { allowEdit &&
              <Button
                className="m-auto text-sm block my-2 w-48"
                onClick={ () => {
                  navigateTo(MediaStep, {
                    orderId: order.id,
                    designId: design.id,
                  }, { returnHere: true })
                }}>Edit Photos / Videos</Button>
            }
          </div>
        </div>

        { shrink &&
        <div className="bg-gray-100 p-2 font-bold text-sm mt-1 cursor-pointer" onClick={ () => setShrunk(!shrunk) }>
          { shrunk ? "Show details" : "Hide details" }
        </div>
        }

      </header>
      <main className={ (shrunk ? " hidden" : "") }>
        <ul className="space-y-4">
        {
          destinations?.map(destination => (
            <li key={ destination.id } className="bg-gray-50 p-4">
              <DestinationSummary
                order={ order }
                designId={ design.id }
                destination={ destination }
                allowEdit={ allowEdit }
              />
            </li>
          ))
        }
        { allowEdit &&
          <li key="new" className="bg-gray-50 p-4">
            <Button
              className="m-auto text-sm block my-2 w-72"
              onClick={ () => {
                navigateTo(AddressStep, {
                  orderId: order.id,
                  designId: design.id,
                  add: true,
                }, { returnHere: true })
              }}
            >Send Another Copy</Button>
          </li>
        }
        </ul>
      </main>
    </div>
  )
}
