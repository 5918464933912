import React, { useState, useEffect } from 'react'
import { ViewGridAddIcon } from '@heroicons/react/outline'
import { isMobile } from 'react-device-detect'

import type { MediaEntryFragment } from '../graphql/__generated__'
import SortableMediaViewer from './SortableMediaViewer'
import Loader from './Loader'

import {
  DndContext,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core'

import MediaDropTarget from './MediaDropTarget'

type MediaListingProps = {
  segments: MediaEntryFragment[]
  allMedia: MediaEntryFragment[]
  designId: string
  onRemove(id: string): void
  onReorder(ids: string[]): void
  onOpenUpload(): void
}

export default function MediaListing(props: MediaListingProps) {
  const [segments, setSegments] = useState<MediaEntryFragment[]>(props.segments)
  const [mediaBeingDragged, setMediaBeingDragged] = useState<MediaEntryFragment | null>(null)

  useEffect(() => {
    setSegments(props.segments)
  }, [props.segments])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor)
  );

  function handleDragEnd(event: any) {
    const {active, over} = event;

    setMediaBeingDragged(null);

    if (active && over) {
      const from = segments.findIndex(segment => segment.id === active.id)
      const to = +over.id + 1
      const segment = segments[from]

      if (segment) {
        let newSegments = segments.slice()
        newSegments.splice(from, 1)
        newSegments.splice((from < to ? to - 1 : to), 0, segment)
        setSegments(newSegments)

        props.onReorder(newSegments.map((m: MediaEntryFragment) => m.id))
      }
    }
  }
  function handleDragStart(event: any) {
    const { active } = event;

    const beingDragged = segments.find(segment => segment.id === active.id)
    if (beingDragged) {
      setMediaBeingDragged(beingDragged)
    }
  }

  return <>
    <DndContext
      sensors={ sensors }
      collisionDetection={ closestCorners }
      onDragStart={ handleDragStart }
      onDragEnd={ handleDragEnd }
    >

      <ul className="flex flex-wrap mx-[-0.5rem]">
        <li key="drop:-1">
          <MediaDropTarget index={ -1 } />
        </li>
        {segments?.map((media: MediaEntryFragment, i: number) => {
          return [
            mediaBeingDragged === media ? <></> :
            <li key={ media.id + ":" + i } className="w-1/2 sm:w-1/4 md:w-1/5 my-2 flex flex-col items-center">
              <SortableMediaViewer
                media={ media }
                designId={ props.designId }
                onRemove={ () => props.onRemove(media.id) }
                allMedia={ props.allMedia }
              />
            </li>,
            <li key={ "drop:" + i }> 
              <MediaDropTarget index={ i } />
            </li>
          ]
        })}
        <li key="add" className="flex flex-grow flex-shrink-0 p-2">
          <a onClick={ props.onOpenUpload } className="flex flex-col items-center justify-center
                                 w-full min-w-[10rem] h-[10rem] bg-gray-50 rounded
                                 shadow cursor-pointer">
            <span className="text-center text-gray-500 text-xs font-bold">
              <ViewGridAddIcon className="m-auto block w-12 h-12" />
              <span className="w-full block mt-2">
                { isMobile
                  ? "Add Media"
                  : "Drop Photos and Videos Here"
                }</span>
            </span>
          </a>
        </li>
      </ul>
      <DragOverlay>
        { mediaBeingDragged &&
          <SortableMediaViewer media={ mediaBeingDragged }  designId={ props.designId } showIcons={ false } playable={ false } />
        }
      </DragOverlay>
    </DndContext>
  </> }
