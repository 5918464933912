import React, { useState, useEffect, useCallback } from 'react'
import type { RefObject } from 'react'

export const PUBLIC_PATH = "https://public.sendheirloom.com/music"

export type Song = {
  id: string,
  title: string,
  artist: string,
}

export const MUSIC: Array<Song> = [
  {
    id: 'Andy_Ellison_Small_Town_Sunset_instrumental_2_33',
    title: 'Small Town Sunset',
    artist: 'Andy Ellison',
  },
  {
    id: 'Lone_Canyon_Bounding_Souls_background_vocals_2_57',
    title: 'Bounding Souls',
    artist: 'Lone Canyon',
  },
  {
    id: 'Dario_Benedetti_I_Knew_instrumental_2_18',
    title: 'I Knew',
    artist: 'Dario Benedetti',
  },
  {
    id: 'Anchor_Walker_instrumental_2_20',
    title: 'Walker',
    artist: 'Anchor',
  },
  {
    id: 'Antioch_Mass_Choir_Amazing_Grace_lead_vocal_2_32',
    title: 'Amazing Grace',
    artist: 'Antioch Mass Choir',
  },
  {
    id: 'Avocado_Junkie_Sticky_Stuff_instrumental_2_55',
    title: 'Sticky Stuff',
    artist: 'Avocado Junkie',
  },
  {
    id: 'Braeden_Rangno_New_York_Strut_instrumental_2_07',
    title: 'New York Strut',
    artist: 'Braeden Rangno',
  },
  {
    id: 'Centerpiece_Love_Like_This_lead_vocal_3_56',
    title: 'Love Like This',
    artist: 'Centerpiece',
  },
  {
    id: 'Colder_Months_You_Belong_Here_Stripped_lead_vocal_3_42',
    title: 'You Belong Here',
    artist: 'Colder Months',
  },
  {
    id: 'Daniele_Musto_Seabreeze_background_vocals_3_04',
    title: 'Seabreeze',
    artist: 'Daniele Musto',
  },
  {
    id: 'Door_to_Penglai_Kokedera_instrumental_3_02',
    title: 'Kokedera',
    artist: 'Door to Penglai',
  },
  {
    id: 'Dreamlamp_We_Wish_You_A_Merry_Christmas_Stripped_lead_vocal_2_34',
    title: 'We Wish You A Merry Christmas',
    artist: 'Dreamlamp',
  },
  {
    id: 'Ian_Kelosky_Blue_to_the_Bone_instrumental_2_19',
    title: 'Blue to the Bone',
    artist: 'Ian Kelosky',
  },
  {
    id: 'Joshua_Nichols_After_All_These_Years_Stripped_lead_vocal_3_30',
    title: 'After All These Years',
    artist: 'Joshua Nichols',
  },
  {
    id: 'Luna_Wave_You_And_I_lead_vocal_3_40',
    title: 'You And I',
    artist: 'Luna Wave',
  },
  {
    id: 'Mikey_Geiger_Slow_Motion_Stripped_Version_feat_Jessie_Villa_lead_vocal_3_35',
    title: 'Slow Motion',
    artist: 'Mikey Geiger',
  },
  {
    id: 'Mississippi_Lightnin_Rambler_Blues_instrumental_2_53',
    title: 'Rambler Blues',
    artist: 'Mississippi Lightnin\'',
  },
  {
    id: 'Outside_The_Sky_Counting_Breaths_instrumental_4_48',
    title: 'Counting Breaths',
    artist: 'Outside The Sky',
  },
  {
    id: 'Sounds_Like_Sander_Neverending_Minute_instrumental_2_40',
    title: 'Neverending Minute',
    artist: 'Sounds Like Sander',
  },
  {
    id: 'Third_Age_Three_Goddesses_instrumental_2_36',
    title: 'Three Goddesses',
    artist: 'Third Age',
  },
  {
    id: 'Violet_Mourning_10_Million_Suns_lead_vocal_3_05',
    title: '10 Million Suns',
    artist: 'Violet Mourning',
  },
  {
    id: 'VISITANTS_Better_Than_Two_lead_vocal_3_21',
    title: 'Better Than Two',
    artist: 'VISITANTS',
  },
]

type CurrentlyPlaying = {
  pause: () => void
  url: string | undefined
}

let currentlyPlaying: null | CurrentlyPlaying = null

export default function useAudio(url: string | undefined) {
  const [audio] = useState(url ? new Audio(url) : null)
  const [playing, setPlaying] = useState(false)

  const pause = () => setPlaying(false)

  useEffect(() => {
    if (!audio) {
      return
    }

    if (playing) {
      if (currentlyPlaying && currentlyPlaying.url !== url) {
        currentlyPlaying.pause()
      }
      currentlyPlaying = {
        pause,
        url,
      }

      audio.play()
    } else {
      audio.pause()
    }
  }, [audio, playing])

  useEffect(() => {
    const handler = () => setPlaying(false)

    audio?.addEventListener('ended', handler)
    return () => {
      audio?.removeEventListener('ended', handler)
    }
  }, [audio])

  const seek = useCallback((timeSeconds: number) => {
    if (audio) {
      audio.currentTime = timeSeconds
    }
  }, [audio])

  return { playing, setPlaying, seek }
}

type AudioState = {
  context: AudioContext
  source: MediaElementAudioSourceNode
  gain: GainNode
}

export function useVolume(videoRef: RefObject<HTMLVideoElement|HTMLAudioElement>, mediaVolume: number) {
  let [audio, setAudio] = useState<AudioState | null>(null)
  useEffect(() => {
    if (!videoRef.current) {
      return
    }

    let source: MediaElementAudioSourceNode | null = null
    let context: AudioContext | null = null
    let gain: GainNode | null = null
    if (audio) {
      source = audio.source
      context = audio.context
      gain = audio.gain
      if (audio.source.mediaElement !== videoRef.current) {
        // We don't do this in the deregistration function as we can't actually
        // bind to the same <video> element twice. To prevent this, we use this
        // existance test, with the understanding that we will occasionally leak
        // an audio context.
        source.disconnect()
        gain.disconnect()
        context.close()

        audio = null
      }
    }

    if (!audio) {
      context = new AudioContext()
      source = context.createMediaElementSource(videoRef.current)
      gain = context.createGain()

      source.connect(gain)
      gain.connect(context.destination)

      setAudio({ context, source, gain })
    }
  }, [videoRef.current, audio])

  useEffect(() => {
    if (audio?.gain) {
      // Our goal is for .6 to equal a gain of 1, which is no gain at all (as 0.6 is 'normal' volume).
      // We want 1.0 to be almost clipping for most inputs.
      // This is based on a quadratic regression on:
      // 0.2=0.4
      // 0.4=0.4
      // 0.6=1
      // 1.0=2.3
      // NOTE This is also in the Worker, if you change it here, change it there too.
      audio.gain.gain.value = 1.875 * Math.pow(mediaVolume, 2) + 0.425 * mediaVolume
    }
  }, [mediaVolume, audio?.gain])
}

