import React from 'react'

import type { CustomerBalanceFragment } from '../graphql/__generated__'

type CustomerBalanceSummaryProps = {
  balance: CustomerBalanceFragment
}

export default function CustomerBalanceSummary({ balance }: CustomerBalanceSummaryProps) {
  return <ul>
    { balance.cashInCents > 0 &&
      <li
        key="cash"
        className="bg-gray-100 rounded p-2 mr-2 mb-2 inline-block text-center"
      >
        <span className="block text-3xl text-pink-500 font-bold">
          ${ (balance.cashInCents / 100).toFixed(2) }
        </span>
        <span>Balance</span>
      </li>
    }

    { balance.products.map((product) =>
      <li
        className="bg-gray-100 rounded p-2 mr-2 mb-2 inline-block text-center"
        key={ product.product.id }>
        <span className="block text-3xl text-pink-500 font-bold">{ product.quantity }</span>
        <span>{ product.product.displayName }{ product.quantity === 1 ? '' : 's' }</span>
      </li>
    ) }

    <li
      key="buy"
      className="inline-block"
      >
      <a
        href="https://sendheirloom.com/?flow=order"
        className="cursor-pointer bg-pink-500 rounded p-2 mr-2 inline-block hover:bg-pink-300
                   focus:outline-white">
        <span className="mr-1 block text-3xl text-center text-white font-bold">+</span>
        <span className="text-white">Buy More</span>
      </a>
    </li>
  </ul>
}
