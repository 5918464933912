import React from 'react'
import { useFormContext } from 'react-hook-form'

import type { Extra } from '../graphql/__generated__'
import { formatPrice } from '../utils/Price'

export type CoverFormFields = {
  giftBox: boolean
  coverId: string
}

export default function GiftBoxFormInput() {
  const { register } = useFormContext<CoverFormFields>();

  return  (
    <div className="mt-6 md:grid grid-cols-4 gap-2">
      <div className="col-span-3 self-center">
        <p>
          <label className="flex">
            <input
              {...register("giftBox")}
              type="checkbox"
              className="
                flex-shrink-0
                form-tick
                appearance-none
                cursor-pointer
                h-8
                w-8
                mt-[3px]
                border border-gray-300
                rounded
                checked:bg-black
                checked:border-transparent
                focus:outline-none
              "
            />
            <span className="text-lg ml-4"
              >Include a Gift Box [{ formatPrice(1200) }]
            </span>
          </label>
        </p>
        <p className="mt-1 ml-2 pl-10 opacity-50">Your video book will be packed in our custom-designed gift box and secured with a beautiful bow.</p>
      </div>
      <div
        className="w-full md:flex-shrink-0 mx-auto mt-4 md:mt-0">
        <div
          className="block w-full h-0 relative bg-center bg-no-repeat bg-cover rounded shadow"
          style={{
            backgroundImage: "url('https://cdn.shopify.com/s/files/1/0457/5667/8307/products/SeindHeirloomFlatlayAbsoluteFinals_48copy.png?v=1637351758')",
            paddingTop: "68%",
          }}
        >
        </div>
      </div>
    </div>
  )
}
