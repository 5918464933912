import React, { useRef } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import './App.css'
import Orders from './pages/Orders'
import DesignEditor from './pages/DesignEditor'
import AddressEditor from './pages/AddressEditor'
import CoverEditor from './pages/CoverEditor'
import MessageEditor from './pages/MessageEditor'
import OrderSummary from './pages/OrderSummary'
import Checkout from './pages/Checkout'
import Onboarding from './pages/Onboarding'
import LocalWelcome from './pages/LocalWelcome'
import OrientationSelect from './pages/OrientationSelect'
import DownloadDesign from './pages/DownloadDesign'
import TransferDesign from './pages/TransferDesign'
import AssembleBook from './pages/AssembleBook'
import Help from './pages/Help'
import AuthLinkLogin from './components/AuthLinkLogin'
import ScrollToTop from './components/ScrollToTop'
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
  // This allows users to drop files anywhere on the page to upload them.
  const pageRef = useRef(null)

  return (
    <Router>
      <div ref={ pageRef } className="min-h-screen font-sans">
        <Header />

        <main className="p-2 md:p-6 lg:p-10 bg-white rounded shadow-xl max-w-[960px] m-auto">
          <Routes>
            <Route path="/" element={<Onboarding />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/order/:orderId/design" element={<DesignEditor dropContainer={ pageRef } />} />
            <Route path="/order/:orderId/design/:designId" element={<DesignEditor dropContainer={ pageRef } />} />
            <Route path="/order/:orderId/design/:designId/destination/:destinationId" element={<DesignEditor dropContainer={ pageRef } />} />
            <Route path="/order/:orderId/design/:designId/message" element={<MessageEditor />} />
            <Route path="/order/:orderId/design/:designId/destination/:destinationId/message" element={<MessageEditor />} />
            <Route path="/order/:orderId/design/:designId/cover" element={<CoverEditor />} />
            <Route path="/order/:orderId/design/:designId/destination/:destinationId/cover" element={<CoverEditor />} />
            <Route path="/order/:orderId/design/:designId/address" element={<AddressEditor />} />
            <Route path="/order/:orderId/design/:designId/destination/:destinationId/address" element={<AddressEditor />} />
            <Route path="/order/:orderId/payment" element={<Checkout />} />
            <Route path="/order/:orderId" element={<OrderSummary />} />
            <Route path="/help" element={<Help />} />
            <Route path="/local" element={<LocalWelcome />} />
            <Route path="/local/design" element={<OrientationSelect />} />
            <Route path="/local/design/:designId" element={<OrientationSelect />} />
            <Route path="/local/design/:designId/media" element={<DesignEditor dropContainer={ pageRef } />} />
            <Route path="/local/design/:designId/download" element={<DownloadDesign />} />
            <Route path="/local/design/:designId/transfer" element={<TransferDesign />} />
            <Route path="/local/design/:designId/assemble" element={<AssembleBook />} />
            <Route path="/auth/link" element={<AuthLinkLogin />} />
          </Routes>
        </main>

        <Footer />

        <ScrollToTop />
      </div>
    </Router>
  )
}

export default App
