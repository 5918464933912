import React from 'react'

import type { MediaEntryFragment, Maybe } from "../graphql/__generated__"

type MediaStackProps = {
  media: Maybe<MediaEntryFragment[]>
  tileQty?: number
}

const DEFAULT_QTY = 4

export default function MediaStack({ media, tileQty }: MediaStackProps) {
  if (!media) {
    return <></>
  }

  const qty = tileQty || DEFAULT_QTY

  media = media.filter(m => m.type !== 'music')

  const elClasses = `
    max-w-[118px] max-h-[118px]
  `

  // We use 120px because thumbnails are currently encoded at 240x240, and we like retina.
  return (
    <ul className="flex h-[120px]">
      {
        media.slice(0, qty).map((media, i) => (
          <li key={ media.id + ":" + i } className="relative">
            { media.signedThumbnailLocation &&
              <div className="w-[120px] h-[120px] border border-gray-200 bg-white shadow mr-2 rounded overflow-hidden flex justify-center">
                { media.type === 'video' && media.signedVideoThumbnailLocation ?
                  <video
                    className={ elClasses }
                    playsInline
                    muted
                    poster={ media.signedThumbnailLocation }
                    src={ media.signedVideoThumbnailLocation }
                    onMouseOver={ (e) => { e.currentTarget.play() }}
                    onMouseLeave={ (e) => { e.currentTarget.pause(); e.currentTarget.currentTime = 0 }} />
                :
                  <div
                    className={ elClasses + "  bg-center bg-contain bg-no-repeat w-full h-full" }
                    style={{ backgroundImage: `url("${ media.signedThumbnailLocation }")` }} />
                }
              </div>
            }
          </li>
        ))
      }
      {
        media.length > qty &&
          <li className="font-bold text-gray-500 items-center flex">
            <div>and { media.length - qty } more...</div>
          </li>
      }
    </ul>
  )
}
