import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'

import { AddDesignDocument, AddDesignMutation, DesignFragment } from '../graphql/__generated__'
import Button from './Button'
import DesignChooser from './DesignChooser'

type NewDesignProps = {
  orderId: string
  onCreate: (newDesign: DesignFragment) => undefined|void
}

type Choice = 'new' | 'copy' | undefined

export default function NewDesignOptions({ orderId, onCreate }: NewDesignProps) {
  const [choice, setChoice] = useState<Choice>(undefined)
  const [cloneDesignId, setCloneDesignId] = useState<string>('')
  const [addDesign, { loading, data, error }] = useMutation(AddDesignDocument, {
    onError: (error: ApolloError) => {
      console.error("Error creating new design", error)
    },
    onCompleted: ({ addDesign }: AddDesignMutation) => {
      const { design } = addDesign
      onCreate && onCreate(design)
    },
  })

  function submit() {
    if (choice === 'new') {
      addDesign({variables: {orderId}})
    } else if (choice === 'copy' && cloneDesignId !== '') {
      addDesign({variables: {orderId, cloneDesignId}})
    }

    return undefined
  }

  if (loading) {
    return <div className="flex justify-center mt-8"><CircularProgress size="12em" /></div>
  }

  const chooser = <div className="border-y-4 border-blue-200 mt-2">
    <DesignChooser onChange={ setCloneDesignId } />
  </div>

  const toggle = <div className="grid grid-cols-2 mt-12">
    <div>
      <button
        className={"block m-auto w-80 h-80 p-6 border-2 border-blue-500 hover:bg-blue-100" + (choice === 'new' ? ' bg-blue-100' : '')}
        onClick={ () => setChoice('new') }
      >
        <h2 className="mb-6 text-lg font-bold text-gray-900">Start from Scratch</h2>
        <svg xmlns="http://www.w3.org/2000/svg" className="m-auto h-20 w-20 mb-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
        </svg>
        <span className="text-gray-600">Build a book by uploading new videos and photos.</span>
      </button>
    </div>

    <div>
      <button
        className={"block m-auto w-80 h-80 p-6 border-2 border-blue-500 hover:bg-blue-100" + (choice === 'copy' ? ' bg-blue-100' : '')}
        onClick={ () => setChoice('copy') }
      >
        <h2 className="mb-6 text-lg font-bold text-gray-900">Copy an Existing Book</h2>
        <svg xmlns="http://www.w3.org/2000/svg" className="m-auto h-20 w-20 mb-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
        </svg>
        <span className="text-gray-600">Start with the photos and videos of one of your existing books.</span>
      </button>
      <div className={ "w-0 h-0 mt-2 m-auto border-[40px] border-solid border-transparent border-b-0 border-t-blue-200" + (choice === 'copy' ? ' block' : ' hidden') }></div>
    </div>
  </div>

  return <>
    { error ? <div className="bg-red-300 p-4 my-2">{ error.message }</div> : null }

    { toggle }

    { choice === 'copy' && chooser }

    <div className="grid justify-items-end mt-12">
      <Button
        type="primary"
        onClick={ submit }
        disabled={ choice === undefined || (choice === 'copy' && cloneDesignId === '') }
      >Next</Button>
    </div>
  </>
}
