import React, { useRef } from 'react'
import type { FormEvent } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'

import LoginModal from '../components/LoginModal'
import Button from '../components/Button'
import ButtonLink from '../components/ButtonLink'
import DiscountForm from '../components/DiscountForm'
import OrderPricingSummary from '../components/OrderPricingSummary'
import OrderBalanceUsage from '../components/OrderBalanceUsage'
import { OrderDocument } from '../graphql/__generated__'
import PaymentFormInput, { PaymentFormInputRef } from '../components/PaymentFormInput'
import useSteps, { BuilderFlow, CheckoutStep } from '../utils/Steps'

/* Note that some payment methods are 'delayed', meaning we won't
 * hear about their failure in the UI, only through a hook later.
 *
 * We don't currently even listen to this hook, but we will need to,
 * and will need to notify the user of failures, if we ever want
 * to support delayed payment methods like ACH and Buy Now/Pay Later.
 */

export default function Checkout() {
  const { orderId } = useParams()
  const { data, loading, error } = useQuery(OrderDocument, {
    variables: {
      orderId,
    },
    onError: (err: ApolloError) => {
      console.error("Error loading order for order summary", err)
    },
  })
  const { nextPath, currentStep, currentPath } = useSteps(
    BuilderFlow,
    CheckoutStep,
    { orderId })
  const order = data?.orderStatus

  const paymentRef = useRef<PaymentFormInputRef | null>(null)

  if (!orderId) {
    return <div>No order.</div>
  }
  if (loading) {
    return <div className="flex justify-center"><CircularProgress /></div>
  }

  let singleBook = true
  if (order?.designs?.length > 1) {
    singleBook = false
  } else {
    if (order.designs[0].destinations.length > 1) {
      singleBook = false
    }

    if (order.designs[0].destinations[0].quantity > 1) {
      singleBook = false
    }
  }

  const terms = singleBook ? {'book': 'book', 'it is': 'it is'} : {'book': 'books', 'it is': 'they are'}

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    paymentRef.current?.submit()
  }

  return <div className="max-w-xl m-auto">
    <h1 className="text-xl mb-6">Checkout</h1>

    { error && <p className="p-2 my-4 bg-red-200">Error loading order.</p> }

    <div className="p-4 space-y-3 bg-gray-100 text-sm">
      <h2 className="text-xl pb-2">A Message From The Heirloom Team</h2>
      <p>
        Building a video book isn't always easy; we appreciate your hard work and creativity.
        We are confident your { terms['book'] } will be exceptional. If for any reason { terms['it is'] } not,
        we will provide a full refund or replacement.
      </p>

      <p>
        Your { terms['book'] } will be shipped quickly after your order is placed. If you have any questions or
        concerns, please <a href="mailto:help@sendheirloom.com" className="text-blue-500 cursor-pointer">contact us</a> before placing your order.
      </p>

      <p>
        Thank you for entrusting us with your cherished memories,
      </p>

      <div>
        <div className="font-bold">
          Ashley & Zack
        </div>
        <div>
          Co-founders
        </div>
      </div>
    </div>

    <div className="max-w-xl mx-auto my-4">
      { order &&
        <>
          { order.cost?.total.total.listInCents > 0 &&
            <>
              <OrderPricingSummary order={ order } />

              <div className="flex justify-end">
                <DiscountForm order={ order } />
              </div>
            </>
          }

          <div className="mt-2">
            <OrderBalanceUsage order={ order } />
          </div>

          <form className="m-auto" onSubmit={ submit }>
            <div className="max-w-xl p-6">
              <PaymentFormInput
                order={ order }
                ref={ paymentRef }
                nextPath={ `/order/${ order.id }/?thanks=true` }
              />
            </div>

            <div className="mt-4 grid grid-cols-2">
              <ButtonLink
                className="justify-self-start"
                to={ `/order/${ order.id }` }
                type="secondary">Edit Order</ButtonLink>

              <Button
                className="justify-self-end"
                type="primary">Submit Order</Button>
            </div>
          </form>
        </>
      }
    </div>

    <LoginModal />
  </div>
}
