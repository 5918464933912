import React, { useState, useMemo, useEffect, useRef } from 'react'
import type { RefObject } from 'react'
import { isMobile } from 'react-device-detect'

import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import DropTarget from '@uppy/drop-target'
import Webcam from '@uppy/webcam'
import Url from '@uppy/url'
import Dashboard from '@uppy/dashboard'
import ScreenCapture from '@uppy/screen-capture'
import GoogleDrive from '@uppy/google-drive'
import GoldenRetriever from '@uppy/golden-retriever'
import ProgressBar from '@uppy/progress-bar'

import useWindowDimensions from '../utils/WindowDimensions'
import { MediaEntry } from '../graphql/__generated__'

type UploaderProps = {
  children: JSX.Element
  onUploaded: (entry: {id: string}) => void
  dropContainer: RefObject<HTMLElement>
  showUpload: boolean
  onUploadShown: () => void
}

type ActiveFile = {
  filename: string
  progress?: number
}

const GB = 1024 * 1024 * 1024
const COMPANION_URL = localStorage.DEBUG_local_companion ? 'http://localhost:4323' : 'https://companion.sendheirloom.com'

export default function Uploader({ children, onUploaded, dropContainer, showUpload, onUploadShown }: UploaderProps) {
  const [activeFiles, setActiveFiles] = useState<{[key: string]: ActiveFile}>({})
  const [error, setError] = useState<string | undefined>(undefined)
  const dashContainer = useRef<HTMLDivElement>(null)

  const { width } = useWindowDimensions()

  const uppy = useMemo(() => new Uppy({
    meta: { type: 'avatar' },
    restrictions: {
      maxFileSize: 10 * GB,
    },
    autoProceed: true,
  }), [Uppy])

  const removePlugin = (pluginId: string) => {
    const plugin = uppy.getPlugin(pluginId)
    if (plugin) {
      uppy.removePlugin(plugin)
    }
  }

  useEffect(() => {
    uppy.use(Tus, {
//      endpoint: 'https://upload.sendheirloom.com/files/',
      endpoint: 'https://upload-staging.sendheirloom.com/files/',

      // Larger is a bit better, as it has to include all paths in the final call,
      // and too large of a header tends to cause issues.
      chunkSize: 1024 * 1024 * 15,

      retryDelays: [100, 1000, 3000, 5000],

      limit: 20,
    })

    uppy.use(ProgressBar, {
      target: 'body',
      fixed: true,
      hideAfterFinish: true,
    })

    uppy.use(Webcam, {
      id: 'Webcam',
      modes: ['video-audio'],
      showRecordingLength: true,
      showVideoSourceDropdown: true,
      videoConstraints: {
        width: { ideal: 854 },
        height: { ideal: 480 },
        facingMode: 'environment',
      },
      locale: {
        strings: {
          pluginNameCamera: (isMobile ? 'Camera' : 'Webcam'),
        },
      },
    })

    uppy.use(GoogleDrive, {
      companionUrl: COMPANION_URL,
    })

    /*uppy.use(Facebook, {
      companionUrl: COMPANION_URL,
    })*/

    uppy.use(Url, {
      companionUrl: COMPANION_URL,
      title: 'YouTube / Vimeo / Facebook Link',
    })

//    uppy.use(GoldenRetriever, { serviceWorker: true })

    if (!isMobile) {
      uppy.use(ScreenCapture, {
        id: 'ScreenCapture',
        // @ts-ignore They neglected to include this in the type
        title: 'Capture Screen',

        displayMediaConstraints: {
          video: {
            width: 854,
            height: 480,
            aspectRatio: 16 / 9,
            frameRate: {
              ideal: 30,
              max: 30,
            },
            cursor: 'never',
            displaySurface: 'monitor',
          },
        },
        preferredVideoMimeType: 'video/mp4',
      })
    }

    uppy.on('file-added', onStart)
    uppy.on('upload-success', onFinish)
    uppy.on('upload-error', onError)

    // We don't currently unregister, as uppy shouldn't change
  }, [uppy])

  useEffect(() => {
    if (showUpload) {
      const button = dashContainer.current?.querySelector('button.uppy-Dashboard-browse')
      if (button) {
        (button as HTMLButtonElement).click()
      }
      onUploadShown()
    }
  }, [showUpload, dashContainer.current])

  useEffect(() => {
    if (dropContainer.current) {
      const dt = uppy.getPlugin('DropTarget')

      if (!dt) {
        uppy.use(DropTarget, {
          target: dropContainer.current,
        })
      } else {
        dt.uninstall()
        dt.setOptions({
          target: dropContainer.current,
        })
        dt.install()
      }
    }
  }, [dropContainer.current])

  useEffect(() => {
    if (dashContainer.current) {
      uppy.use(Dashboard, {
        target: dashContainer.current,
        plugins: ['Webcam', 'ScreenCapture', 'GoogleDrive', 'Url'],
        inline: true,
        fileManagerSelectionType: 'both',
        proudlyDisplayPoweredByUppy: false,
        hideProgressAfterFinish: true,
        showProgressDetails: true,
        showSelectedFiles: false,
        disableStatusBar: true,
        width: '100%',
        locale: {
          strings: {
            myDevice: isMobile ? 'My Device' : 'My Computer',
          },
        },
      })
    }

    return () => {
      removePlugin('Dashboard')
    }
  }, [dashContainer, width])

  type NumToString = {[key: number]: string}

  let uploadOrder: string[] = new Array()
  let latestUpload: number = 0
  let pendingUploads: NumToString = {}
  function onFinish(file: any, response: any){
    const url = response.uploadURL

    // It's a bit of a wonky process to get the id from the upload response, as
    // tus doesn't make it easy to return the actual destination location.
    const id = url.split('+')[0].split('/').pop()

    let index = 0
    for (let i=0; i < uploadOrder.length; i++) {
      if (uploadOrder[i] === file.id) {
        index = i
        break
      }
    }

    pendingUploads[index] = id
    processPendingQueue()
  }

  function processPendingQueue() {
    // Files get uploaded in whatever order, but we want to preserve the order
    // they were added to the uploader.
    for (let i=latestUpload; i < uploadOrder.length; i++){
      if (pendingUploads[i]) {
        if (pendingUploads[i] !== '<error>') {
          onUploaded({id: pendingUploads[i]})
        }
        latestUpload++
      } else {
        break
      }
    }
  }

  function onError(err: any){
    // TODO set pendingUploads[x] to false so the rest of the uploads get processed?
    console.error("Error uploading", err)
    setError(err.uploadResponse)
  }

  function onStart(file: any) {
    uploadOrder.push(file.id)
    setError(undefined)
  }

  return <div className="min-w-full">
    { children }

    <div ref={ dashContainer }></div>
  </div>
}
