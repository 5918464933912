import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import LoginModal from '../components/LoginModal'
import MessageForm from '../components/MessageForm'
import DestComponentEditor from '../components/DestComponentEditor'
import { useQueryParams } from '../utils/Query'
import useSteps, { BuilderFlow, MessageStep } from '../utils/Steps'
import type { DestinationFragment } from '../graphql/__generated__'

export default function MessageEditor() {
  const navigate = useNavigate()
  const queryParams = useQueryParams()
  const { orderId, designId, destinationId } = useParams()
  const { navigateNext, nextPath, currentPath, hasReturn } = useSteps(
    BuilderFlow,
    MessageStep,
    { orderId, destinationId, designId })

  if (!orderId) {
    return <>Missing order id</>
  }
  if (!designId) {
    return <div>No design ID</div>
  }

  return <div className="w-[50rem]">
    <DestComponentEditor
      form={ MessageForm }
      text={{
        pageTitle: "Message",
        addTitle: "Add Message",
        editTitle: "Message Inside Cover",
      }}
      showSkip={ true }
      add={ queryParams.get('add') !== null }
      orderId={ orderId }
      destinationId={ destinationId }
      designId={ designId }
      hasReturn={ hasReturn }
      onSaveDest={ (dest: DestinationFragment) => {
        navigate(currentPath({ destinationId: dest.id }) || '', {
          replace: true,
        })
      }}
      onStartNewDest={ (designId: string) => {
        navigate(currentPath({ designId, destinationId: undefined }))
      }}
      onDone={ () => {
        navigateNext({})
      } }
    />

    <p className="my-4 text-slate-500 text-sm">
      Want to send multiple books with different messages? Enter the first message here,
      more options will appear on a later page.
    </p>

    <LoginModal />
  </div>
}
