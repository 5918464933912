import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'

import { DesignDocument } from '../graphql/__generated__'
import type { RenderFragment } from '../graphql/__generated__'
import useSteps, { LocalFlow, TransferStep } from '../utils/Steps'

export default function TransferDesign() {
  const { designId } = useParams()
  const { prevPath } = useSteps(LocalFlow, TransferStep, { designId })

  const { data, loading, error } = useQuery(DesignDocument, {
    variables: {
      designId,
    },
    onError: (err: ApolloError) => {
      console.error("Error loading design for transfer design", err)
    },
  })
  const design = data?.design

  // TODO: Make this correct
  const orientation = "horizontal"

  const render = design.renders.find((render: RenderFragment) =>
    render.formFactor === 'square' && render.task === 'share' && orientation === 'horizontal')

  const sharePath: string | undefined = render?.url

  return <>
    <h1>Transfer Design</h1>

    <h3>1. Connect your book to your computer</h3>
    <p>
      Using the cable included with your book, connect it to your computer. If you don't have a cable,
      contact us for a replacement.
    </p>

    <h3>2. Navigate to the book on your computer</h3>
    <p>
      Your book should appear as a drive on your computer. It may appear on your desktop, or in your
      file browser under the name "Untitled".
    </p>

    <h3>3. Remove any existing videos</h3>
    <p>
      Navigate to the 'Video' folder on your book. Delete any existing videos.
    </p>

    <h3>4. Transfer the new video file</h3>
    <p>
      Drag the video file downloaded in the <a href={ prevPath({}) }>previous step</a> into the
      'Video' folder on your book.
    </p>

    <h3>5. Eject your book</h3>
    <p>
      Eject your book from your computer. This will ensure it is correctly disconnected and your
      file is fully transferred.
    </p>

    <p>You should now be able to open your book and see your video play! Be sure to remove any protective
    film which may remain on your book before giving it as a gift.</p>

    { sharePath &&
      <a
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        href={ sharePath }>Share Your Video</a>
    }
  </>
}
