import type { ApolloCache } from '@apollo/client'
import type { OrderFragment } from '../graphql/__generated__'
import { ShippingOptionsDocument } from '../graphql/__generated__'

export function formatPrice(val: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: (val % 100 !== 0) ? 2 : 0,
  }).format(val / 100)
}

export function resetCost(cache: ApolloCache<any>, order: OrderFragment) {
  cache.modify({
    id: cache.identify(order),
    fields: {
      cost(existingCost, { DELETE }) {
        return DELETE
      },
    },
  })
}

export var REFETCH_ON_COST_CHANGE = ['ShippingOptions']
