import React from 'react'

import { formatDuration } from '../utils/Duration'
import type { MediaEntryFragment } from '../graphql/__generated__'

type MediaUsageBarProps = {
  media: MediaEntryFragment[]
}

const capacity = 20 * 60

export default function MediaUsageBar({ media }: MediaUsageBarProps) {
  let usedSeconds = 0
  for (let i = 0; i < media.length; i++) {
    const entry = media[i]

    if (entry.clips.length) {
      for (let j = 0; j < entry.clips.length; j++) {
        const clip = entry.clips[j]
        usedSeconds += (clip.endMs - clip.startMs) / 1000
      }
    } else {
      usedSeconds += entry.durationSeconds || 0
    }
  }

  const usagePercent = Math.min(100 * usedSeconds / capacity, 100)

  return (
    <div className="flex flex-col justify-end pr-1 pb-1">
      <div className="text-xs md:text-sm pb-1 text-gray-500">
        Used { formatDuration(usedSeconds) } of <span className="inline-block">{ formatDuration(capacity) }</span>
      </div>

      <div className="w-full bg-gray-25 h-3 rounded">
        <div className="bg-gray-100 h-3 rounded" style={{ width: `${ usagePercent }%` }} />
      </div>
    </div>
  )
}

