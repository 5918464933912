import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from '@apollo/client/link/context'
import { makeVar, useReactiveVar } from '@apollo/client'
import type { TypePolicies, FieldMergeFunction } from '@apollo/client'
import DebounceLink from 'apollo-link-debounce'

import { getFieldNames } from './utils/Graphql'
import { MediaEntryFragmentDoc } from './graphql/__generated__'

export const API_HOST = localStorage.DEBUG_local_server ? "http://localhost:8088" : "https://api.sendheirloom.com"

type Token = {
  token: string | undefined
  expiration: number | undefined
}

export const TOKEN_VAR = makeVar<Token>({
  token: localStorage.getItem("token") || undefined,
  expiration: parseInt(localStorage.getItem("tokenExpiration") || '', 10) || undefined,
})

export function isAuthenticated(): boolean {
  const {token, expiration} = TOKEN_VAR()

  if (!token || !expiration) {
    return false
  }

  if (Date.now() > expiration) {
    return false
  }

  return true
}

const httpLink = createHttpLink({
  uri: API_HOST + "/graphql/client",
})

const authLink = setContext((_, { headers }) => {
  const { token } = TOKEN_VAR()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
})

type AuthenticationStatus = {
  isAuthenticated: boolean
  customerId?: string
}

export function useAuthentication(): AuthenticationStatus {
  // We don't use it directly, but it forces rerenders of
  // components which use this hook:
  const tokenV = useReactiveVar(TOKEN_VAR)
  if (!tokenV || tokenV.token == undefined) {
    return {
      isAuthenticated: false,
    }
  }

  const parts = tokenV.token!.split('!')
  const customerId = parts[1]

  return {
    isAuthenticated: isAuthenticated(),
    customerId,
  }
}

export function logout() {
  localStorage.removeItem("email")
  localStorage.removeItem("token")
  localStorage.removeItem("tokenExpiration")
  TOKEN_VAR({
    token: undefined,
    expiration: undefined,
  })
}

// Signed S3 URLs get regenerated often, which continually breaks our client-side
// cache, forcing rerenders. Even if we cache the URLs on the server, it's common
// to hit a new instance and get a new URL. Instead we don't evict URLs unless they
// have really truely changed or have expired.
let keepIfNotExpired: FieldMergeFunction
keepIfNotExpired = (existing, incoming) => {
  if (!existing || !incoming) {
    return incoming
  }

  const iUrl = new URL(incoming)
  const eUrl = new URL(existing)
  if (iUrl.pathname !== eUrl.pathname || iUrl.origin !== eUrl.origin) {
    return incoming
  }

  const date = eUrl.searchParams.get('X-Amz-Date')
  const expiresIn = eUrl.searchParams.get('X-Amz-Expires')
  if (!date || !expiresIn) {
    return incoming
  }

  const expiresAt = +new Date(date) + (1000 * +expiresIn)
  if ((expiresAt - 10 * 1000) < new Date().getTime()) {
    return incoming
  }

  return existing
}

const signedFields = getFieldNames(MediaEntryFragmentDoc).filter(field => field.startsWith('signed'))
let mediaEntryFields: TypePolicies = {}
signedFields.forEach((fieldName: string) => {
  mediaEntryFields[fieldName] = {merge: keepIfNotExpired}
})

const client = new ApolloClient({
  link: ApolloLink.from([
    new DebounceLink(10),
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      MediaEntry: {
        fields: mediaEntryFields,
      },
      Design: {
        fields: {
          media: {
            merge(_, incoming) {
              return incoming
            },
          },
        },
      },
    },
  })
});

export default client
