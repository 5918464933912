import React from 'react'

import { formatPrice } from '../utils/Price'
import type { CostsFragment, CustomerBalanceProductFragment, Maybe } from '../graphql/__generated__'

type PriceSummaryProps = {
  cost: CostsFragment["total"]
  showDiscount?: boolean
  discountReason?: string
}

function PriceSummary({ cost, showDiscount, discountReason }: PriceSummaryProps) {
  return <span>
    { formatPrice(cost.actualInCents) }
    { showDiscount && cost.discountInCents > 0 && <>
      <div className="text-green-600 text-sm" title={ discountReason || '' }>
        &nbsp;saved { formatPrice(cost.discountInCents) }
      </div>
    </> }
  </span>
}

type Field = 'total' | 'tax' | 'shipping' | 'subtotal'

type PricingSummaryProps = {
  costs: Maybe<CostsFragment>
  fields?: Field[]
}

export default function PricingSummary({ costs, fields }: PricingSummaryProps) {
  if (!costs) {
    return <></>
  }

  fields = fields || ['total', 'tax', 'shipping', 'subtotal']
  const balanceProd = costs.balanceUsed.products.find(p => p.product.type === 'player')

  return <>
    <dl className="grid grid-cols-3 max-w-xl mx-auto">
      { fields.includes('subtotal') && <>
        <dt>Books</dt>
        <dd className="text-right col-span-2">
          <PriceSummary cost={ costs.product } />
          { !!balanceProd?.quantity &&
            <div className="text-green-600 text-sm">+ { balanceProd.quantity } prepaid book{ balanceProd.quantity > 1 ? 's' : '' }</div>
          }
        </dd>
      </> }

      { fields.includes('shipping') && costs.shipping.listInCents > 0 && <>
        <dt>Shipping</dt>
        <dd className="text-right col-span-2">
          <PriceSummary
            cost={ costs.shipping }
            showDiscount={ true }
            discountReason="You saved by shipping multiple books to the same address"
          />
        </dd>
      </> }

      { fields.includes('tax') && costs.tax.listInCents > 0 && <>
        <dt>Tax</dt>
        <dd className="text-right col-span-2">
          <PriceSummary cost={ costs.tax } />
        </dd>
      </> }

      { fields.includes('total') && <>
        <dt>Total</dt>
        <dd className="text-right col-span-2">
          <PriceSummary cost={ costs.total } />
        </dd>
      </> }
    </dl>
  </>
}
