import React, { useState } from 'react'
import type { ChangeEvent, MouseEvent } from 'react'

import { UpdateQuantityDocument, RemoveDestinationDocument } from '../graphql/__generated__'
import CircularProgress from '@mui/material/CircularProgress'
import type { ApolloError } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { resetCost, REFETCH_ON_COST_CHANGE } from '../utils/Price'

import Button from './Button'
import type { DestinationFragment, OrderFragment } from '../graphql/__generated__'

type QuantityFormProps = {
  destination: DestinationFragment
  designId: string
  order: OrderFragment
}

export default function QuantityForm({ order, designId, destination }: QuantityFormProps) {
  const [updateQuantity, { loading: qtyLoading, error: qtyError }] = useMutation(UpdateQuantityDocument, {
    update(cache) {
      resetCost(cache, order)
    },
    refetchQueries: REFETCH_ON_COST_CHANGE,
  })
  const [removeDestination, { loading: removeLoading, error: removeError }] = useMutation(RemoveDestinationDocument)
  const [showRemove, setShowRemove] = useState(false)

  const loading = qtyLoading || removeLoading
  const error = qtyError || removeError

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const qty = parseInt(event.target.value)

    if (qty === 0) {
      setShowRemove(true)
    } else {
      setShowRemove(false)

      updateQuantity({
        variables: {
          destinationId: destination.id,
          quantity: qty,
        },
        onError: (err: ApolloError) => {
          console.error("Error saving quantity change", err)
        },
      })
    }
  }

  const remove = () => {
    removeDestination({
      variables: {
        orderId: order.id,
        designId,
        destinationId: destination.id,
      },
      onError: (err: ApolloError) => {
        console.error("Error removing destination", err)
      },
      refetchQueries: ['Order'],
    })
  }

  return <form>
    { showRemove
      ?
        <div className="flex flex-col items-center justify-center">
          <Button
            onClick={ (event: MouseEvent<HTMLButtonElement>): undefined => {
              event.preventDefault()
              remove()
              return
            }}
            type="danger"
          >
            Remove
          </Button>
          <button onClick={ (event: MouseEvent<HTMLButtonElement>): undefined => {
            event.preventDefault()
            setShowRemove(false)
            return
          }}>
            Cancel
          </button>
        </div>
      :
        <select
          defaultValue={ destination.quantity || 1 }
          onChange={ handleChange }
          className="rounded"
        >
          <option value={ 0 }>Remove</option>
          {/*
            // @ts-ignore TS doesn't like this weird iterator style */}
          {[...Array(99).keys()].map(i => <option key={i + 1} value={i + 1}>{i + 1}</option>)}
        </select>
    }
    { loading && <CircularProgress size='1em ml-2' /> }
    { error && <div className="m-2 p-2 bg-red-500">{ error.message }</div> }
  </form>
}

