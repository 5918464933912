import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import Button from './Button'
import Preview from './Preview'
import type { PreviewProps } from './Preview'

type ModalPreviewProps = PreviewProps & {
  show: boolean
  onClose: () => void
}

export default function ModalPreview(props: ModalPreviewProps) {
  const { show, onClose } = props

  if (!show) {
    return <></>
  }

  return <Dialog
    onClose={ onClose }
    open={ show }
    fullWidth={ true }
    maxWidth={ 'lg' }
  >
    <DialogTitle>
      Preview Your Video Book

      <a
        title="Close preview"
        className="absolute top-4 right-4"
        onClick={ onClose }>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </a>
    </DialogTitle>

    <DialogContent>
      <div className="flex items-center justify-center">
        <Preview { ...props } />
      </div>
    </DialogContent>

    <DialogActions>
      <div className="m-auto">
        <Button
          onClick={ onClose }>
          Close
        </Button>
      </div>
    </DialogActions>
  </Dialog>
}
