import React from 'react';
import {useDroppable} from '@dnd-kit/core';

type MediaDropTargetProps = {
  index: number
};

export default function MediaDropTarget({ index }: MediaDropTargetProps) {
  const {setNodeRef, isOver} = useDroppable({
    id: index.toString(),
  });

  let style: any = {}
  if (!isOver) {
    style.display = 'none'
  }

  return (
    <div ref={ setNodeRef }>
      <div style={ style }
        className='h-[10em] w-[10em] bg-blue-100
                   m-2 flex justify-center items-center
                   font-bold text-gray-600
                   border-solid border-2 border-sky-500'>
        Place Here
      </div>
    </div>
  );
}
