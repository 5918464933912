import React from 'react'

import type { OrderFragment } from '../graphql/__generated__'
import DesignSummary from '../components/DesignSummary'
import ButtonLink from '../components/ButtonLink'

type OrderEditProps = {
  order: OrderFragment
}

export type DesignWithDestinationsFragment = NonNullable<OrderFragment["designs"]>[0]

export default function OrderEdit({ order }: OrderEditProps) {
  const showBorder = order?.designs && order.designs.length > 1

  return <div>
    <ul>
    {
      order?.designs?.map((design: DesignWithDestinationsFragment, i: number) => {
        if (design.design) {
          return <li key={ design.design.id }
            className={"flex flex-col mt-2" + (showBorder ? " p-2 border-gray-200 border-4" : "")}>

            <DesignSummary
              allowEdit={ true }
              order={ order }
              design={ design.design }
              destinations={ design.destinations || null } />
          </li>
        }
      })
    }
    </ul>

    <div className="mt-4">
      { order &&
        <ButtonLink
          className="m-auto text-sm block"
          to={ `/order/${ order.id }/design` }>+ Design a New Book</ButtonLink>
      }
    </div>

    <div className="max-w-full mx-auto mt-4 flex justify-end">
      <ButtonLink
        to={ `/order/${ order?.id }/payment` }
        disabled={ !order }
        type="primary">Review Order</ButtonLink>
    </div>
  </div>
}
