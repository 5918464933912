import React, { useState, useMemo } from 'react'

import type { MediaEntryFragment } from '../graphql/__generated__'
import MusicBrowser from './MusicBrowser'
import MusicBrowserItem from './MusicBrowserItem'
import { MUSIC } from '../utils/Audio'

type MusicListingProps = {
  segments: Array<MediaEntryFragment> | undefined
  onAdd?: (songId: string, whenDone: () => void) => void
  onRemove?: (songId: string, whenDone: () => void) => void
}

export default function MusicListing({ segments, onAdd, onRemove }: MusicListingProps) {
  const [showBrowser, setShowBrowser] = useState(false)
  const segmentIds = useMemo(() => {
    if (!segments) return []
    return segments.map(({ id }) => id)
  }, [segments])

  return (
    <div>
      <div
        className={ `p-2 mt-2 mb-[-4px] bg-gray-50 cursor-pointer
                     rounded w-full text-sm font-bold` }
        onClick={ () => setShowBrowser(!showBrowser) }>
        <div className="flex justify-between">
          <div className="flex items-center md:shrink-0 text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" className={ "h-6 w-6 transition-transform duration-500" + (showBrowser ? ' -rotate-90' : '') } fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}><path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" /></svg>
            <span className="ml-2">Background Music</span>
          </div>
          <div className={ `justify-between transition-opacity
                            duration-1000 hidden opacity-0` + (showBrowser ? "" : " !flex !opacity-100") }>
            <ul className="flex flex-wrap">
              { segmentIds && segmentIds.map((segmentId) => {
                const song = MUSIC.find(song => song.id === segmentId)
                return <li key={ segmentId } className="inline-block bg-gray-100 rounded p-2 ml-2 my-1
                                                         whitespace-nowrap">
                  { song?.title || 'Your Song' }
                </li>
              })}
            </ul>
          </div>
        </div>
      </div>

      <div className={ `flex pt-2 flex-wrap transition-[height] duration-500
                        overflow-hidden h-0` + (showBrowser ? " h-auto md:h-[340px]" : "") }>
        <div className="md:w-1/2 w-full">
          { <MusicBrowser 
            onAdd={ onAdd } 
            hide={ segmentIds }
          /> }
        </div>

        <div className="md:w-1/2 w-full flex flex-col justify-between">
          <ul>
            { segmentIds && segmentIds.map((segmentId) => (
              <li key={ segmentId }>
                <MusicBrowserItem
                  songId={ segmentId }
                  action='remove'
                  onAction={ (whenDone) => onRemove && onRemove(segmentId, whenDone) }
                />
              </li>
            )) }
          </ul>
          <div className="hidden md:block text-gray-500 p-2 pb-[40px] text-sm text-center">
            Have your own music? Drag any audio file onto this page.
          </div>
        </div>
      </div>
    </div>
  )
}
