import React from 'react'

import type { OrderFragment } from '../graphql/__generated__'
import OrderSummaryTile from './OrderSummaryTile'

type OrderSummaryListingProps = {
  orders: [OrderFragment]
}

export default function OrderSummaryListing({ orders }: OrderSummaryListingProps){
  return <div className="w-full overflow-y-auto">
    <ul className="flex py-2">
      { orders.map((o: OrderFragment) =>
        <li key={ o.id }>
          <OrderSummaryTile order={ o } />
        </li>
      ) }
    </ul>
  </div>
}
