import React, { useCallback, forwardRef, useImperativeHandle } from 'react'
import type { FormEvent, RefObject } from 'react'
import { useMutation } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import { UpdateAddressDocument, UpdateCoverDocument, AddDestinationDocument, AddExtraDocument, RemoveExtraDocument } from '../graphql/__generated__'
import CircularProgress from '@mui/material/CircularProgress'
import type { ApolloError } from '@apollo/client'

import type { UpdateCoverMutation, AddExtraMutation, RemoveExtraMutation, DestinationFragment, StockFragment} from '../graphql/__generated__'
import type { FormRef, DestFormProps } from './DestComponentEditor'
import Button from './Button'
import CoverFormInput from './CoverFormInput'
import GiftBoxFormInput from './GiftBoxFormInput'
import type { CoverFormFields } from './GiftBoxFormInput'
import GiftMessageInput from './GiftMessageInput'
import QuantityFormInput from './QuantityFormInput'
import InlineCover from './InlineCover'
import { REFETCH_ON_COST_CHANGE } from '../utils/Price'
import { findDestination } from '../utils/Destination'

type CoverFormProps = DestFormProps & {
  stock?: StockFragment | undefined,
}

const CoverForm = forwardRef(({ order, destination, designId, onSave, onError, stock }: CoverFormProps, formRef) => {
  const [updateCover, { loading: coverSaving, error: coverSaveError }] = useMutation(UpdateCoverDocument)
  const [addExtra, { loading: addExtraSaving, error: addExtraSaveError }] = useMutation(AddExtraDocument, {
    refetchQueries: REFETCH_ON_COST_CHANGE,
  })
  const [removeExtra, { loading: removeExtraSaving, error: removeExtraSaveError }] = useMutation(RemoveExtraDocument, {
    refetchQueries: REFETCH_ON_COST_CHANGE,
  })
  const methods = useForm<CoverFormFields>({
    defaultValues: {
      coverId: destination?.coverId || '',
      giftBox: destination && hasGiftBox(),
    }
  })
  // We use a FormProvider to make the form accessible to CoverFormInput
  const { handleSubmit } = methods

  useImperativeHandle(formRef, () => ({
    submit: handleSubmit(submit),
  }))

  function hasGiftBox(): boolean {
    return !!destination?.extras?.find(extra => extra.id === 'giftbox-5in')
  }

  function saveCover(values: CoverFormFields) {
    if (!destination?.id) {
      throw new Error("No destination set")
    }

    return new Promise((resolve, reject) => {
      updateCover({
        variables: {
          destinationId: destination?.id,
          coverId: values.coverId,
        },
        onError: (err: ApolloError) => {
          console.error("Error saving address change", err)
          reject(err.message)
        },
        onCompleted: (resp: UpdateCoverMutation) => {
          const order = resp.updateCover
          // TODO A lot of these could have a bug if you change which entry you're editing while
          // its saving.
          const dest = findDestination(order, destination?.id)
          resolve(dest)
        },
      })
    })
  }

  function saveGiftBox({ giftBox }: CoverFormFields): Promise<DestinationFragment|undefined> {
    if (!destination?.id) {
      throw new Error("No destination set")
    }

    return new Promise((resolve, reject) => {
      const opts = {
        onError: (err: ApolloError) => {
          console.error("Error saving extras change", err)
          reject(err.message)
        },
      }

      if (giftBox && !hasGiftBox()) {
        addExtra({...opts,
          variables: {
            destinationId: destination?.id,
            extra: {
              id: 'giftbox-5in',
            },
          },
          onCompleted: (resp: AddExtraMutation) => {
            const order = resp.addExtra
            const dest = findDestination(order, destination?.id)
            resolve(dest)
          },
        })
      } else if (!giftBox && hasGiftBox()) {
        removeExtra({...opts,
          variables: {
            destinationId: destination?.id,
            id: 'giftbox-5in',
          },
          onCompleted: (resp: RemoveExtraMutation) => {
            const order = resp.removeExtra
            const dest = findDestination(order, destination?.id)
            resolve(dest)
          },
        })
      } else {
        resolve(undefined)
      }
    })
  }

  async function submit(values: CoverFormFields) {
    try {
      // Giftbox doesn't give us a dest if it doesn't need to save
      await saveGiftBox(values)
      const dest = await saveCover(values)
      console.log('b')
      console.log('x', dest, onSave)
      dest && onSave && onSave(dest as DestinationFragment)
    } catch (e) {
      console.log("Error saving cover and gift box", e)
      onError && onError(e as string)
    }
  }

  const saving = coverSaving || addExtraSaving || removeExtraSaving
  const saveError = coverSaveError?.message || addExtraSaveError?.message || removeExtraSaveError?.message

  return <div>
    <FormProvider {...methods}>
      <form onSubmit={ handleSubmit(submit) }>
        <div className="mx-auto md:mx-4 space-y-4">
          <CoverFormInput coverId={ destination?.coverId || '' } stock={ stock } />
          <GiftBoxFormInput />
        </div>
      </form>
    </FormProvider>
  </div>
})

export default CoverForm
