import React from 'react'

import MusicBrowserItem from './MusicBrowserItem'
import { MUSIC } from '../utils/Audio'

type MusicBrowserProps = {
  onAdd?: (songId: string, whenDone: () => void) => void
  hide: Array<string> | undefined
}

export default function MusicBrowser({ onAdd, hide }: MusicBrowserProps) {
  return <div className="border border-gray-200 rounded bg-white">
    <h2 className="text-lg font-bold p-2 text-gray-800">Select New Music</h2>
    <ul className="space-y-2 overflow-y-auto max-h-[15em] bg-gray-50 border-t border-gray-200">
      { MUSIC
        .filter(song => !hide || !hide.includes(song.id))
        .map(song => (
          <li key={ song.id }>
            <MusicBrowserItem
              songId={ song.id }
              action='add'
              onAction={ (whenDone) => { onAdd && onAdd(song.id, whenDone) } }
            />
          </li>
      )) }
    </ul>
  </div>
}
