import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import useAudio, { PUBLIC_PATH, MUSIC } from '../utils/Audio'

type Action = 'add' | 'remove'

type MusicBrowserItemProps = {
  onAction?: (whenDone: () => void) => void
  action: Action | undefined
  songId: string
}

export default function MusicBrowserItem({ songId, action, onAction }: MusicBrowserItemProps) {
  const [active, setActive] = useState(false)

  const doAction = () => {
    if (onAction) {
      setActive(true)
      onAction(() => setActive(false))
    }
  }

  const song = MUSIC.find(song => song.id === songId)

  const audio = useAudio(song && `${ PUBLIC_PATH }/${ song.id }.mp3`)

  let icon = <></>
  if (audio.playing) {
      icon = <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 m-auto opacity-90 hover:opacity-100" viewBox="0 0 20 20" fill="white">
        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" style={{ stroke: "black", strokeWidth: "0.25px" }} clipRule="evenodd" />
      </svg>
  } else {
      icon = <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 m-auto opacity-90 hover:opacity-100" viewBox="0 0 20 20" fill="white">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" style={{ stroke: "black", strokeWidth: "0.25px" }} clipRule="evenodd" />
      </svg>
  }

  let actionButton = <></>
  if (active) {
    actionButton = <div><CircularProgress size="1.5em" /></div>
  } else if (action === 'add') {
    actionButton = (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
      </svg>
    )
  } else if (action === 'remove') {
    actionButton = (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M20 12H4" />
      </svg>
    )
  }

  const togglePlayback = () => {
    if (song) {
      audio.seek(0)
      audio.setPlaying(!audio.playing)
    }
  }

  return (
    <div className="grid grid-cols-12 hover:bg-blue-100
                    p-1 px-2">
      <div
        className={ `w-12 h-12 bg-gray-100 bg-cover bg-center rounded-lg
                  shadow-lg flex items-center
                  col-span-2` + ( song ? ' cursor-pointer' : '' ) }
        onClick={ togglePlayback }
        style={ song ? { backgroundImage: `url("${ PUBLIC_PATH }/${ song.id }.jpeg")` } : {} }
      >
        { song && icon }
      </div>
      <div className="col-span-9">
        { song ? <>
          <div className="font-bold">{ song.title }</div>
          <div className="text-gray-500">{ song.artist }</div>
        </> : <>
          <div className="font-bold">Your Song</div>
          <div className="text-gray-500">User Uploaded</div>
        </> }
      </div>
      { actionButton &&
        <div className="col-span-1 flex items-center justify-end
                        cursor-pointer hover:opacity-100 opacity-80"
            onClick={ doAction }>
          { actionButton }
        </div>
      }
    </div>
  )
}
