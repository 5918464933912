export const formatDuration = (seconds: number) => {
  let parts = []
  while (seconds > 0) {
    const part = seconds % 60
    parts.push(Math.round(part))
    seconds = Math.floor(seconds / 60)
  }

  if (parts.length <= 1) {
    return `${ parts[0] || 0 }s`
  }
  if (parts.length === 2 && parts[0] === 0) {
    return `${ parts[1] } min`
  }

  const out = parts.reverse().map(part => part.toString().padStart(2, '0')).join(':')
  if (out[0] === '0') {
    return out.slice(1)
  }
  return out
}
