import React from 'react'
import { CheckIcon } from '@heroicons/react/solid'

import { classNames } from '../utils/classes'
import useSteps from '../utils/Steps'

type StepStatus = 'current' | 'upcoming' | 'complete'

export default function Steps() {
  const { currentStep, currentFlow, steps, path } = useSteps()
  console.log('current', currentFlow)
  if (!currentFlow || !steps) return <></>

  return (
    <nav aria-label="Progress" className="w-full h-22 pt-4 pb-10 hidden md:block">
       <ol role="list" className="flex items-center justify-center">
        {steps.map((step, stepIdx) => {
          if (step.showInNav === false) {
            return null
          }

          return <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '', 'relative')}>
            {step.status === 'complete' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-green-500" />
                </div>
                <a
                  href={ path(step) }
                  className="relative w-8 h-8 flex items-center justify-center bg-green-500 rounded-full hover:bg-green-700"
                >
                  <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                  <div className="mt-16 text-xs text-green-500 uppercase font-bold absolute">{ step.name }</div>
                </a>
              </>
            ) : step.status === 'current' ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-green-300" />
                </div>
                <a
                  href={ path(step) }
                  className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-green-500 rounded-full"
                  aria-current="step"
                >
                  <span className="h-2.5 w-2.5 bg-green-500 rounded-full" aria-hidden="true" />
                  <div className="mt-16 text-xs text-green-500 uppercase font-bold absolute">{ step.name }</div>
                </a>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-0.5 w-full bg-green-300" />
                </div>
                <a
                  href={ path(step) }
                  className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-green-300 rounded-full hover:border-gray-400"
                >
                  <span
                    className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <div className="mt-16 text-xs text-green-500 uppercase font-bold absolute">{ step.name }</div>
                </a>
              </>
            )}
          </li>
        })}
      </ol>
    </nav>
  )
}

