import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import { useAuthentication } from '../apollo-client'
import { useNavigate, Link } from 'react-router-dom'
import { CameraIcon, GiftIcon } from '@heroicons/react/outline'

import useSteps from '../utils/Steps'
import ProgressOverlay from '../components/ProgressOverlay'
import { CreateDraftOrderDocument, OrdersDocument, MeDocument } from '../graphql/__generated__'
import LoginModal from '../components/LoginModal'
import OrderSummaryListing from '../components/OrderSummaryListing'
import CustomerBalanceSummary from '../components/CustomerBalanceSummary'

export default function Onboarding() {
  // TODO Query for an existing design
  // Show the design options if multiple exist
  const email = localStorage.getItem('email')
  const navigate = useNavigate()

  // Reset to hide steps in nav
  useSteps()

  const { customerId } = useAuthentication()

  const { data, loading: queryLoading, error: queryError } = useQuery(OrdersDocument, {
    variables: {
      customerId,
    },
    onError: (error: ApolloError) => {
      console.error("Error querying orders", error)
    }
  })

  const { data: meData, loading: meLoading, error: meError } = useQuery(MeDocument, {
    onError: (error: ApolloError) => {
      console.error("Error querying customer record", error)
    }
  })

  const [createDraftOrder, { loading: createLoading, error: createError }] = useMutation(CreateDraftOrderDocument, {
    variables: {
      order: {
        customerEmail: email,
      }
    },
    onError: (error: ApolloError) => {
      console.error("Error creating draft order", error)
    }
  })

  const loading = queryLoading || createLoading || meLoading
  const error = queryError || createError || meError

  const orders = data?.customerOrders
  const draftOrders = orders?.filter((o: any) => o.state === 'draft')
  const doneOrders = orders?.filter((o: any) => o.state !== 'draft')
  const me = meData?.me

  function buildNewBook() {
    createDraftOrder({
      onCompleted: (data) => {
        const order = data.createDraftOrder.order
        navigate(`/order/${ order.id }/design/${ order.designs[0].design.id }/cover`)
      }
    })
  }

  function sendGift() {

  }

  return <>
    <LoginModal />

    { loading && <ProgressOverlay /> }

    { draftOrders?.length > 0 &&
      <div className="mb-4 bg-gray-50 p-4">
        <h2 className="text-xl mb-2">Draft Orders</h2>
        <p className="text-sm mb-6">Continue working where you left off.</p>
        <OrderSummaryListing orders={ draftOrders } />
      </div>
    }

    <div className="bg-gray-50 rounded mb-4 p-4">
      <h2 className="text-xl mb-2">Start a New Order</h2>
      <p className="mb-6 text-sm">We are here to help you get your video book built! Do you have your photos and videos ready to upload?</p>

      { error && <p className="p-4 my-2 bg-red-200">There was an error creating your book, please try again.</p> }

      <a
        onClick={ buildNewBook }
        tabIndex={ 0 }
        className="block w-full h-48 cursor-pointer shadow p-6 my-3
                  bg-white focus:outline-0 focus:border-green-300 hover:border-green-500
                  flex rounded border-pink-300 border-4
        ">
        <div className="hidden w-32 mr-8 md:flex items-center justify-center">
          <CameraIcon className="w-14 h-14 md:w-24 md:h-24 text-pink-300" />
        </div>

        <div className="self-center">
          <h2 className="text-xl font-bold mb-2">I have my videos and photos ready</h2>
          <p>If you are ready to upload your videos, we can get started building your book.</p>
        </div>
      </a>

      <a
        onClick={ sendGift }
        tabIndex={ 0 }
        className="block w-full h-48 cursor-pointer shadow p-6 my-3
                  bg-white focus:outline-0 focus:border-green-300 hover:border-green-500
                  flex rounded border-pink-300 border-4
        ">
        <div className="hidden w-32 mr-8 md:flex items-center justify-center">
          <GiftIcon className="w-14 h-14 md:w-24 md:h-24 text-pink-300 md:ml-[14px]" />
        </div>

        <div className="self-center">
          <h2 className="text-xl font-bold mb-2">I would like a blank book or gift card</h2>
          <p>We can send you a blank book your recipient can load themselves, or a gift card for them to redeem.</p>
        </div>
      </a>
    </div>

    { me && (me.balance.products || me.balance.cashInCents) &&
      <div className="mb-4 p-4 rounded bg-gray-50">
        <div className="mb-6">
          <h2 className="text-lg">Your Balance</h2>
          <span className="text-sm">These credits will automatically be applied against the books you build.</span>
        </div>
        <CustomerBalanceSummary balance={ me.balance } />
      </div>
    }

    <p className="mb-4 md:mb-0 mt-8 text-center block">Need help? You can always
      <Link to="/help"
         className="focus:outline-pink-300 text-pink-500 ml-1"
      >contact us</Link>
    .</p>

    { doneOrders?.length > 0 &&
      <div>
        <h2>Check on your order status</h2>
        <OrderSummaryListing orders={ doneOrders } />
      </div>
    }

  </>
}
