import React from 'react'
import { useQuery } from '@apollo/client'
import type { ApolloError } from '@apollo/client'

import { getCover } from '../utils/Covers'
import { StockDocument } from '../graphql/__generated__'

type CoverImageProps = {
  coverId: string
}

export default function CoverImage({ coverId }: CoverImageProps) {
  const { data, loading, error } = useQuery(StockDocument, {
    onError: (err: ApolloError) => {
      console.error("Error loading stock for cover image", err)
    },
  })

  if (!data || !coverId) {
    return <></>
  }

  const cover = getCover(data.stock, coverId)
  if (!cover || !cover?.images) {
    return <>Cover not found.</>
  }

  return (
    <div>
      <div
        style={{ backgroundImage: `url(${ cover.images[0] || '' })` }}
        title={`Book Cover: ${ cover.displayName }`}
        className="bg-cover bg-center bg-no-repeat w-32 h-32"
      />
    </div>
  )
}
