import React, { forwardRef, useImperativeHandle, useCallback } from 'react'
import type { FormEvent, MouseEvent } from 'react'
import { useMutation } from '@apollo/client'
import { useForm, FormProvider } from 'react-hook-form'
import GiftMessageInput from './GiftMessageInput'
import type { ApolloError } from '@apollo/client'

import type { FormRef, DestFormProps } from './DestComponentEditor'
import { UpdateGiftMessageDocument } from '../graphql/__generated__'
import type { Destination, Order, DestinationFragment, UpdateGiftMessageMutation } from '../graphql/__generated__'
import Button from './Button'
import CircularProgress from '@mui/material/CircularProgress'
import GiftMessagePreview from './GiftMessagePreview'
import { findDestination } from '../utils/Destination'
import type { MessageFormFields } from './GiftMessageInput'

const MessageForm = forwardRef(({ order, destination, onSave, onError }: DestFormProps, formRef) => {
  const [updateGiftMessage, { loading: saving, error: saveError }] = useMutation(UpdateGiftMessageDocument)

  const methods = useForm()
  const { watch, setValue, handleSubmit } = methods

  const messageBody = watch("giftMessage.body", destination?.giftMessage?.body || "")

  useImperativeHandle(formRef, () => ({
    submit: handleSubmit(submit),
  }))

  const submit = useCallback(function (values: MessageFormFields) {
    if (!destination?.id) {
      throw new Error("No destination set")
    }

    updateGiftMessage({
      variables: {
        orderId: order.id,
        destinationId: destination?.id,
        message: values.giftMessage,
      },
      onError: (err: ApolloError) => {
        console.error("Error saving message change", err)
        onError && onError(err.message)
      },
      onCompleted: (resp: UpdateGiftMessageMutation) => {
        const order = resp.updateGiftMessage
        const dest = findDestination(order, destination?.id)
        dest && onSave && onSave(dest)
      },
    })
  }, [destination?.id])

  const existed = false

  return <div>
    <FormProvider { ...methods }>
      <form onSubmit={ methods.handleSubmit(submit) }>
        <GiftMessagePreview message={ messageBody || null } />

        <GiftMessageInput message={ destination?.giftMessage || null } />
      </form>
    </FormProvider>
  </div>
})

export default MessageForm
