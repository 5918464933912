import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import AddressForm from '../components/AddressForm'
import DestComponentEditor from '../components/DestComponentEditor'
import LoginModal from '../components/LoginModal'
import { useQueryParams } from '../utils/Query'
import useSteps, { BuilderFlow, AddressStep } from '../utils/Steps'
import type { DestinationFragment } from '../graphql/__generated__'

export default function AddressEditor() {
  const navigate = useNavigate()
  const queryParams = useQueryParams()
  const { orderId, destinationId, designId } = useParams()
  const { navigateNext, currentPath, hasReturn } = useSteps(
    BuilderFlow,
    AddressStep,
    { orderId, destinationId, designId })

  if (!orderId) {
    return <>Missing order id</>
  }
  if (!designId) {
    return <div>No design ID</div>
  }

  return <div>
    <DestComponentEditor
      form={ AddressForm }
      text={{
        pageTitle: "Shipping Address",
        addTitle: "Add Address",
        editTitle: "Destination Address",
      }}
      add={ queryParams.get('add') !== null }
      orderId={ orderId }
      destinationId={ destinationId }
      designId={ designId }
      hasReturn={ hasReturn }
      onSaveDest={ (dest: DestinationFragment) => {
        navigate(currentPath({ destinationId: dest.id }) || '', {
          replace: true,
        })
      }}
      onStartNewDest={ (designId: string) => {
        navigate(currentPath({ designId, destinationId: undefined }))
      }}
      onDone={ () => {
        navigateNext({})
      }}
    />

    <LoginModal />
  </div>
}
