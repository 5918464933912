import React, { useState, useEffect, useRef, MouseEvent } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'

import Button from '../components/Button'
import DestinationSummaryListing from '../components/DestinationSummaryListing'
import { findDestination } from '../utils/Destination'
import { OrderDocument, AddDestinationDocument, AddDestinationMutation } from '../graphql/__generated__'
import type { OrderFragment, DestinationFragment } from '../graphql/__generated__'
import { REFETCH_ON_COST_CHANGE } from '../utils/Price'
import type { DesignWithDestinationsFragment } from '../components/OrderEdit'

import type PathFn from '../utils/Steps'

export type FormRef = {
  submit: () => number
  saving: boolean
}

type DestComponentEditorProps = {
  add: boolean
  showSkip?: boolean
  orderId: string
  destinationId?: string
  designId?: string

  hasReturn: boolean
  onSaveDest: (dest: DestinationFragment) => void
  onStartNewDest: (designId: string) => void
  onDone: () => void

  text: {
    componentName?: string
    pageTitle?: string
    addTitle?: string
    editTitle?: string
  }

  form: any,
}

export type DestFormProps =  {
  order: OrderFragment
  designId: string
  destination?: DestinationFragment
  onSave?: (destination: DestinationFragment) => void
  onError?: (error: string) => void
}

export default function DestComponentEditor({
  add,
  showSkip,
  orderId,
  destinationId,
  designId,

  onSaveDest,
  onStartNewDest,
  onDone,

  hasReturn,
  form,
  text

  }: DestComponentEditorProps) {

  const FormComponent = form

  const [showAddForm, setShowAddForm] = useState(add)
  const [currentlyEditing, setCurrentlyEditing] = useState<DestinationFragment | null>(null)
  const [hasNoDestinations, setHasNoDestinations] = useState(false)
  const [saving, setSaving] = useState(false)

  const { data, loading, error: loadingError } = useQuery(OrderDocument, {
    variables: {
      orderId,
    },
    onError: (err: ApolloError) => {
      console.error("Error loading order for dest component editor", err)
    },
  })
  const [addDestination, { loading: destSaving, error: destSaveError }] = useMutation(AddDestinationDocument, {
    refetchQueries: REFETCH_ON_COST_CHANGE,
  })

  const order = data?.orderStatus
  const stock = data?.stock

  const showOnlyOne = destinationId !== undefined

  useEffect(() => {
    if (order && destinationId) {
      const destination = findDestination(order, destinationId)
      destination && setCurrentlyEditing(destination)
    } else if (order) {
      if (order.designs) {
        for (let i=0; i < order.designs.length; i++){
          if (order.designs[i].destinations?.length > 0) {
            setHasNoDestinations(false)
            return
          }
        }
      }

      setHasNoDestinations(true)
    }
  }, [order, destinationId])

  const formRef = useRef<FormRef | null>(null)

  const submit = async () => {
    if (!formRef.current){
      return
    }

    setSaving(true)

    if (!currentlyEditing?.id) {
      // @ts-ignore it doesn't respect the formRef.current check above
      await createDestination(async () => await formRef.current.submit())
    } else {
      // @ts-ignore it doesn't respect the formRef.current check above
      await formRef.current.submit()
    }
  }

  async function createDestination(next: () => void) {
    // This destination has never been saved, let's make it before
    // we save the address.
    await addDestination({
      variables: {
        orderId: order.id,
        designId: designId,
      },
      onError: (err: ApolloError) => {
        console.error("Error adding destination", err)
      },
      onCompleted: (resp: AddDestinationMutation) => {
        const order = resp.addDestination
        const design = order.designs?.find((d) => d?.design?.id === designId)

        // The new one always gets added at the end
        const dest = design?.destinations && design.destinations[design.destinations.length - 1]

        if (!dest) {
          throw new Error('No new destination returned')
        }

        // It's a very good idea for the person who uses this
        // form to change the dest passed in to us when a dest
        // is created. If the dest is in the URL, it should be
        // navigated.
        next()
      },
    })
  }

  const onSave = (dest: DestinationFragment) => {
    cancelEditAndAdd()

    setSaving(false)
    onSaveDest(dest)
    if (!showAddForm && (showOnlyOne || !currentlyEditing)) {
      onDone()
    }
  }

  const addEntry = async (designId: string) => {
    if (showAddForm) {
      const resp = await submit()
    }
    setCurrentlyEditing(null)
    setShowAddForm(true)

    // This should set showOnlyOne to false
    onStartNewDest(designId)
  }

  const cancelEditAndAdd = () => {
    setCurrentlyEditing(null)
    setShowAddForm(false)
  }

  const onError = () => {
    setSaving(false)
  }

  const hasDestination = order && !!order.designs.find((d: DesignWithDestinationsFragment) => d.destinations && d.destinations.length > 0)
  const multipleDesigns = order && order.designs.length > 1

 return <div className="w-[50rem] m-auto pb-4 flex flex-col">
    { !showOnlyOne &&
      <div className="mb-2">
        <h1 className="text-xl font-bold">{ text.pageTitle }</h1>
      </div>
    }

    { loading && <div className="flex my-4 justify-center"><CircularProgress /></div> }
    { loadingError && <p className="p-2 m-4 bg-red-300">Error loading order.</p> }
    { destSaveError && <p className="p-2 m-4 bg-red-300">Error saving.</p> }

    { hasDestination && !showOnlyOne &&
      <DestinationSummaryListing
        order={ order }
        componentName={ text.componentName || '' }
        currentlyEditing={ currentlyEditing }
        onEdit={ (dest) => setCurrentlyEditing(dest) }
        onCancel={ cancelEditAndAdd }
        onAdd={ (design) => design?.id && addEntry(design.id) }
        />
    }

    { (((!hasDestination || showAddForm) && designId) || currentlyEditing) && <div className="relative">
        { hasDestination && <>
          <h2 className="text-xl mb-4 font-bold">{ currentlyEditing ? text.editTitle : text.addTitle }</h2>
          { !showOnlyOne &&
            <button onClick={ cancelEditAndAdd } className="absolute top-0 right-0">
              cancel
            </button>
          }
        </> }
        <FormComponent
          order={ order }
          designId={ designId || currentlyEditing?.designId || '' }
          /* @ts-ignore TS doesn't get what type currentlyEditing has */
          destination={ currentlyEditing || undefined }
          stock={ stock }
          onSave={ onSave }
          onError={ onError }
          ref={ formRef }
        />
      </div>
    }

    <div className="mt-12 grid grid-cols-3 justify-items-center">
      <div className="justify-self-start">
        <Button type="secondary">
          Back
        </Button>
      </div>

      <div className="justify-self-center">
        { showSkip &&
          <Button type="secondary" onClick={ (event: MouseEvent<HTMLButtonElement>): undefined => {
            // setValue("giftMessage.body", '')

            onDone()
            return
          }}>
            Skip
          </Button>
        }
      </div>

      <Button
        type="primary"
        className="justify-self-end"
        onClick={ (currentlyEditing || showAddForm || hasNoDestinations) ? submit : onDone }
        active={ saving }>
        { (currentlyEditing || showAddForm || hasNoDestinations) ? 'Save' : (hasReturn ? 'Done' : 'Next') }
      </Button>

      { !showOnlyOne && !currentlyEditing &&
        <p className="col-span-3 text-gray-500 mt-4 text-sm">Add more to have copies of your video book sent to more friends and family members.</p>
      }
    </div>
  </div>
}
