import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MenuIcon, XIcon, UserCircleIcon } from '@heroicons/react/outline'
import { PlusSmIcon, ChevronLeftIcon } from '@heroicons/react/solid'

import ButtonLink from './ButtonLink'
import Logo from './Logo'
import { logout } from '../apollo-client'
import Steps from '../components/Steps'
import useSteps, { BuilderFlow } from '../utils/Steps'
import { classNames } from '../utils/classes'

type NavLinkEntry = {
  label: string
  to: string
}

const navLinks: NavLinkEntry[] = [
  {
    label: 'Build',
    to: '/',
  },
  {
    label: 'Orders',
    to: '/orders',
  },
  {
    label: 'Help',
    to: '/help',
  },
]

const doLogout = async () => {
  logout()

  window.location.reload()
}

export default function Header(){
  const { currentFlow } = useSteps()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [userOpen, setUserOpen] = useState(false)

  const showMenu = !currentFlow

  return (
    <header className="w-full">
      <a href="https://sendheirloom.com" target="_blank">
      </a>

      <nav className="bg-pink-300 shadow-sm h-[20rem] mb-[-16rem]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="-ml-2 mr-2 flex items-center md:hidden">
                {/* Mobile menu button */}
                { showMenu &&
                  <button
                    onClick={ () => setMobileOpen(!mobileOpen) }
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {mobileOpen ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </button>
                }
              </div>
              <div className="flex-shrink-0 flex items-center">
                <Logo />
              </div>
              <div className="hidden md:ml-6 md:flex md:space-x-16">
                { !currentFlow && navLinks.map(({ label, to }, index) => (
                  <NavLink
                    key={ to }
                    to={ to }
                    className={ ({ isActive }) =>
`border-transparent text-gray-700 hover:border-gray-300
hover:text-gray-500 inline-flex items-center px-1 pt-1
border-b-2 font-medium
` + (isActive ? "border-indigo-500 text-gray-900" : '')
                    }
                  >
                    { label }
                  </NavLink>
                )) }
              </div>
            </div>
            <div className="flex items-center">
              { currentFlow === BuilderFlow &&
                <div className="flex-shrink-0">
                  <ButtonLink
                    type="primary"
                    to="/orders"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    <ChevronLeftIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    <span>Exit Builder</span>
                  </ButtonLink>

                </div>
              }
              <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center z-20">
                {/* Profile dropdown */}
                <div className="ml-3 relative">
                  <div>
                    <button
                      className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={ () => setUserOpen(!userOpen) }
                    >
                      <span className="sr-only">Open user menu</span>
                      <UserCircleIcon className="block h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  { userOpen &&
                    <ul className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <li key="sign-out">
                        <a
                          onClick={ doLogout }
                          className='block px-4 py-2 text-sm text-gray-700'
                        >
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        { mobileOpen && showMenu &&
          <div className="absolute shadow rounded md:hidden bg-white z-20">
            <div className="pt-2 pb-3 space-y-1">
              { navLinks.map(({ label, to }, index) => (
                  <NavLink
                    key={ to }
                    to={ to }
                    className={ ({ isActive }) =>
                      `
  block pl-3 pr-4 py-2 border-l-4 text-base font-medium
  sm:pl-5 sm:pr-6 border-transparent text-gray-500
  hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700
  ` + (isActive ? "bg-indigo-50 border-indigo-500 text-indigo-700" : '')
                    }
                  >
                    { label }
                  </NavLink>
                )) }
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4 sm:px-6">
                <div className="flex-shrink-0">
                  <UserCircleIcon className="block h-10 w-10" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <div className="text-sm font-medium text-gray-500">{ localStorage.email || '' }</div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <button
                  onClick={ doLogout }
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100 sm:px-6"
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        }
      </nav>

      <Steps />
    </header>
  )
}
