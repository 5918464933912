import React, { useRef, useEffect } from 'react'
import type { Maybe, Message } from '../graphql/__generated__'
import { renderMessage } from '../deps/loom-client/src/Labels/Message'

type GiftMessagePreviewProps = {
  message: Maybe<string>,
}

export default function GiftMessagePreview({ message }: GiftMessagePreviewProps) {
  const ref = useRef<HTMLDivElement>(null)

  message = message || ''
  message = message.replace(/❤️/g, "♥")

  useEffect(() => {
    renderMessage(message || '', "4x4").then(result => {
      if (ref.current && result?.canvas) {
        ref.current.innerHTML = ''
        ref.current.appendChild(result.canvas)
        result.canvas.style.display = 'block'
      }
    })
  }, [ref.current, message])

  return (
    <div className="relative">
      <img src={ `${ process.env.PUBLIC_URL }/images/message-backdrop.jpg` } />
      <div ref={ ref } className="
        absolute top-48 left-56 transform-gpu origin-top-left scale-50
        rotate-2 skew-x-1	filter grayscale
      " />
    </div>
  )
}
