import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'

import type { Destination, Maybe, DestinationFragment } from "../graphql/__generated__"
import { OrderDocument } from "../graphql/__generated__"
import CoverForm from '../components/CoverForm'
import { useQueryParams } from '../utils/Query'
import DestComponentEditor from '../components/DestComponentEditor'
import useSteps, { BuilderFlow, CoverStep } from '../utils/Steps'

export default function CoverEditor() {
  const navigate = useNavigate()
  const queryParams = useQueryParams()
  const { orderId, designId, destinationId } = useParams()
  const { hasReturn, navigateNext, currentPath } = useSteps(
    BuilderFlow,
    CoverStep,
    { orderId, destinationId, designId })

  if (!orderId) {
    return <div>No order ID</div>
  }
  if (!designId) {
    return <div>No design ID</div>
  }

  return <div className="mb-4">
    <DestComponentEditor
      form={ CoverForm }
      text={{
        pageTitle: "Cover",
        addTitle: "Add Book",
        componentName: "Cover",
      }}
      add={ queryParams.get('add') !== null }
      orderId={ orderId }
      destinationId={ destinationId }
      designId={ designId }
      hasReturn={ hasReturn }
      onSaveDest={ (dest: DestinationFragment) => {
        navigate(currentPath({ destinationId: dest.id }) || '', {
          replace: true,
        })
      }}
      onStartNewDest={ (designId: string) => {
        navigate(currentPath({ designId, destinationId: undefined }))
      }}
      onDone={ () => {
        navigateNext({})
      } }
    />
  </div>
}
