import React from 'react'

import ButtonLink from '../components/ButtonLink'
import useSteps, { LocalFlow, LocalWelcomeStep } from '../utils/Steps'

export default function LocalWelcome(){
  const { nextPath } = useSteps(LocalFlow, LocalWelcomeStep, {})

  return <>
    <h1>Load your Heirloom Video Book</h1>
    <p>We appreciate your video book purchase, and are thrilled to help you get your book loaded.</p>
    <p>There are three steps to loading your book:</p>
    <ol>
      <li>Select your videos and photos.</li>
      <li>Download your combined video.</li>
      <li>Load your combined video onto your video book.</li>
    </ol>
    <p>This process will walk you through these three steps.</p>

    <p>If you run into any trouble at all, please don't hesitate to
      <a href="/contact">Contact Us</a>.
    </p>

    <ButtonLink
      type="primary"
      to={ nextPath({}) }>
      Get Started
    </ButtonLink>
  </>
}
