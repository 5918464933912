import React, { useRef, useState, useEffect, useCallback } from 'react'
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw'

import type { MediaEntryFragment, ClipFragment } from '../graphql/__generated__'
import MediaEditorTimeline from './MediaEditorTimeline'
import { useVolume } from '../utils/Audio'
import Button from './Button'

export type MediaEditorPlayerProps = {
  media: MediaEntryFragment
  clips: ClipFragment[]
  setClips: (clips: ClipFragment[]) => void
  mediaVolume: number
  durationMs: number
  height?: string
  setCurrentTimeMs: (timeMs: number) => void
  setPlaying: (playing: boolean) => void
}

export default function MediaEditorPlayer({ media, height, durationMs, clips, setClips, mediaVolume, setCurrentTimeMs, setPlaying }: MediaEditorPlayerProps) {
  const [currentlyPreviewing, setCurrentlyPreviewing] = useState<boolean>(false)
  const [currentlyPlaying, setCurrentlyPlaying] = useState<boolean>(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [lastUpdateTime, setLastUpdateTime] = useState(0)
  const [currentlyInsideClip, setCurrentlyInsideClip] = useState<ClipFragment | null>()

  useEffect(() => {
    setPlaying(currentlyPlaying)
  }, [currentlyPlaying])

  useEffect(() => {
    setCurrentTimeMs(currentTime)
  }, [currentTime])

  const videoRef = useRef<HTMLVideoElement>(null)
  const onTimeUpdate = useCallback(() => {
    let insideClip: ClipFragment | null = null

    if (videoRef.current && !currentlyPreviewing) {
      const timeMs = videoRef.current.currentTime * 1000

      let nextClip: ClipFragment | undefined
      let prevClip: ClipFragment | undefined

      clips.find((clip) => {
        if (timeMs >= clip.startMs && timeMs <= clip.endMs) {
          insideClip = clip
          return true
        } else if (timeMs < clip.startMs) {
          nextClip = clip
          return true
        }

        prevClip = clip
      })

      // This diff tells us if the user was seeking forward or back. We store
      // lastUpdateTime seperately as currentTime is only updated when we're
      // actually playing (to allow the user to temporarily preview times which
      // wouldn't normally be in the clips).
      const diff = timeMs - lastUpdateTime
      setLastUpdateTime(timeMs)

      if (insideClip) {
        // We're in a clip, we can just update the slider.
        if (currentlyPlaying) {
          // If this happens while we're playing, it means we got an update from the
          // video player and should update the current time.
          //
          // If it happens while we're not playing though, it means we just slid the
          // playback slider (and therefore already know the current time has updated).
          //
          // We could call setCurrentTime anyway, but it can cause issues with fine adjustment
          // of the slider, as the video player rounds the current time (meaning a fine
          // keyboard adjustment might be erased over and over, no matter how often the
          // user tries to do it).
          setCurrentTime(timeMs)
        }
      } else if (nextClip && currentlyPlaying) {
        // Oops, we're outside our clips, better update both the slider and the video.
        // We're currently playing, so we can always know it's the next clip we should
        // move to.
        onSeek(nextClip.startMs)
      } else if (!currentlyPlaying && (nextClip || prevClip)) {
        if (diff > 0) {
          // The user is seeking forward with the keyboard
          onSeek(nextClip?.startMs || clips[clips.length - 1].endMs)
        } else {
          // The user is seeking backward with the keyboard
          onSeek(prevClip?.endMs || clips[0].startMs)
        }
      } else {
        // We've reached the end of our last clip
        setCurrentlyPlaying(false)
        if (clips[0]) {
          onSeek(clips[0].startMs)
        } else {
          onSeek(0)
        }
      }
    }

    setCurrentlyInsideClip(insideClip)

  }, [clips, videoRef.current, currentlyPreviewing, currentlyPlaying, lastUpdateTime])

  const onSeek = (timeMs: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = timeMs / 1000

      // This is necessary both to speed up the update, and because
      // the video time will round to the nearest frame which breaks our
      // keyboard frame-by-frame navigation.
      setCurrentTime(timeMs)
    }

    updateCurrentlyInsideClip()
  }

  const updateCurrentlyInsideClip = () => {
    if (!videoRef.current) {
      setCurrentlyInsideClip(null)
      return
    }

    const time = videoRef.current.currentTime * 1000
    for (let i=0; i < clips.length; i++){
      if (clips[i].startMs <= time && clips[i].endMs >= time){
        setCurrentlyInsideClip(clips[i])
        return
      }
    }
  }

  useEffect(updateCurrentlyInsideClip, [clips, videoRef?.current])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', onTimeUpdate)
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', onTimeUpdate)
      }
    }
  }, [videoRef.current, onTimeUpdate])


  const updateScale = () => {
    let transform = ''
    if (videoRef.current && currentlyInsideClip) {
      transform = `rotate(${ currentlyInsideClip.rotation }deg)`

      const height = videoRef.current?.videoHeight
      const width = videoRef.current?.videoWidth
      if (height && width) {
        const videoIsHorizontal = width > height
        const flipped = currentlyInsideClip.rotation % 180 == 0
        let willBeHorizontal = flipped ? videoIsHorizontal : !videoIsHorizontal

        // When we rotate horizontal / vertical videos will either end up too tall or won't use the full
        // space, so we scale them.
        const scale = height / width
        if (willBeHorizontal !== videoIsHorizontal) {
          transform += ` scale(${ scale })`
        }
      }
    }

    if (videoRef.current){
      videoRef.current.style.transform = transform
    }
  }

  useEffect(updateScale, [videoRef.current, currentlyInsideClip?.rotation])

  // Before the video loads, we don't know if it's horizontal or vertical (as we don't actually provide that
  // in media elements yet)
  useEffect(() => {
    videoRef.current?.addEventListener('durationchange', updateScale)
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('durationchange', updateScale)
      }
    }
  }, [videoRef.current, updateScale])

  type AudioState = {
    context: AudioContext
    source: MediaElementAudioSourceNode
    gain: GainNode
  }

  useVolume(videoRef, mediaVolume)

  useEffect(() => {
    // We bind to the body as getting focus onto this element is tricky while its
    // in the modal.
    const handler = (e: KeyboardEvent) => {
      if (e.key === ' ') {
        togglePlayback()
      }
    }

    document.body.addEventListener('keydown', handler)
    return () => {
      document.body.removeEventListener('keydown', handler)
    }
  })

  useEffect(() => {
    if (!videoRef.current) {
      return
    }

    if (currentlyPlaying) {
      videoRef.current.play()
        .catch((e) => {
          console.error("Error starting playback", e)
        })
    } else {
      videoRef.current.pause()
    }
  }, [currentlyPlaying, videoRef.current])

  // As the user drags a clip, we want to show them what is at that location
  // in the video. We update the video's current time, but we don't update our
  // current time state. When they release the clip, we just update the video
  // back to the current time it was at.
  const previewChange = (timeMs: number) => {
    if (videoRef.current) {
      setCurrentlyPreviewing(true)
      setCurrentlyPlaying(false)
      videoRef.current.currentTime = timeMs / 1000
    }
  }
  const cancelPreviewChange = useCallback(() => {
    if (videoRef.current) {
      setCurrentlyPreviewing(false)
      videoRef.current.currentTime = currentTime / 1000
    }
  }, [currentTime])

  if (!media.signedEncodedLocation) {
    return <div className="bg-gray-100 p-2 my-2">Media is still being processed, and is not ready to be edited yet.</div>
  }

  const togglePlayback = () => {
    setCurrentlyPlaying(!currentlyPlaying)
  }

  const rotateCurrentClip = () => {
    if (!currentlyInsideClip) {
      return
    }

    let rot = currentlyInsideClip.rotation
    rot += 90
    rot %= 360

    let c = clips.slice()
    for (let i=0; i < clips.length; i++){
      if (clips[i] === currentlyInsideClip) {
        c[i] = {...clips[i]}
        c[i].rotation = rot
        setCurrentlyInsideClip(c[i])
      } else {
        c[i] = clips[i]
      }
    }

    setClips(c)
  }

  // We only add clips to the end, so there has to be room on the timeline
  const spaceAvailable = clips.length ? durationMs - clips[clips.length - 1].endMs : 0
  const allowNewClip = spaceAvailable > 5000
  const addClip = () => {
    const lastClip = clips[clips.length - 1]

    setClips([...clips, {
      startMs: Math.round(durationMs - Math.max(spaceAvailable / 2, 4000)),
      endMs: durationMs,
      rotation: lastClip?.rotation || 0,
    }])
  }
  const removeLastClip = () => {
    setClips(clips.slice(0, -1))
  }

  return <div className="relative m-auto max-w-full">
    <div className="relative">
      <video
        playsInline
        crossOrigin="anonymous"
        className="w-full max-w-[854px] max-h-[854px]"
        ref={ videoRef }
        src={ media.signedEncodedLocation }
        poster={ media.signedPosterLocation || '' }
        onClick={ togglePlayback }
        style={{ height: height || '' }}
      />

      { !currentlyPlaying &&
        <div className="absolute inset-0 pointer-events-none flex justify-center items-center">
          <button
            className="pointer-events-auto text-white opacity-80 hover:opacity-100"
            onClick={ togglePlayback }
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20" fill="currentColor" viewBox="0 0 16 16">
              <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
            </svg>
          </button>
        </div>
      }
    </div>

    <div className="py-2 flex h-10">
      <div className="h-[1.7em] mt-[-.1em]">

        <Button
          className="h-full py-1 px-1 mr-3 rounded-none bg-gray-400 hover:bg-gray-500"
          title={ currentlyPlaying ? "Pause playback" : "Resume playback" }
          onClick={ togglePlayback }
        >
          { currentlyPlaying
            ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"/>
                </svg>
              )
            : (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                  <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                </svg>
            )
          }
        </Button>

      </div>

      <div className="flex-grow mr-1">
        <MediaEditorTimeline
          durationMs={ durationMs }
          clips={ clips }
          setClips={ setClips }
          currentTimeMs={ currentTime }
          previewChange={ previewChange }
          cancelPreviewChange={ cancelPreviewChange }
          onSeek={ onSeek }
        />
      </div>

      <div className="h-[1.7em] mt-[-.1em]">
        <Button
          className="h-full py-1 px-1 ml-2 rounded-none bg-gray-400 hover:bg-gray-500"
          title={ allowNewClip ? "Capture another clip" : "Can't add a new clip to the end, move last clip to create space." }
          disabled={ !allowNewClip }
          onClick={ addClip }
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M14.121 14.121L19 19m-7-7l7-7m-7 7l-2.879 2.879M12 12L9.121 9.121m0 5.758a3 3 0 10-4.243 4.243 3 3 0 004.243-4.243zm0-5.758a3 3 0 10-4.243-4.243 3 3 0 004.243 4.243z" />
          </svg>
        </Button>
        { clips.length > 1 &&
          <Button
            className="h-full py-1 px-1 rounded-none bg-gray-300 text-gray-900 hover:bg-gray-400"
            title="Remove last clip"
            onClick={ removeLastClip }
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </Button>
        }
        <Button
          className="h-full py-1 px-1 ml-1 rounded-none bg-gray-400 hover:bg-gray-500 top-[-0.5px] relative"
          title="Rotate clip"
          disabled={ !currentlyInsideClip && !currentlyPreviewing }
          onClick={ rotateCurrentClip }
        >
          <Rotate90DegreesCwIcon sx={{ fontSize: 18, position: 'relative', top: '-3.5px' }} />
        </Button>
      </div>
    </div>
  </div>
}
