import React from 'react'
import { useQuery } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'

import { DesignFragment, DesignsDocument } from '../graphql/__generated__'
import { formatDate } from '../utils/Date'
import MediaStack from './MediaStack'

type DesignChooserProps = {
  onChange: (designId: string) => void
}

export default function DesignChooser({ onChange }: DesignChooserProps) {
  const { loading, error, data } = useQuery(DesignsDocument, {
    onError: (error: ApolloError) => {
      console.error("Error loading designs", error)
    },
  })

  if (loading) {
    return <div className="flex justify-center my-4"><CircularProgress size="6em" /></div>
  }

  if (error) {
    return <div className="p-2 my-2 bg-red-300">Error loading designs</div>
  }

  let designs = data.designs.slice()

  designs.sort((a: DesignFragment, b: DesignFragment) => {
    return +new Date(b.createdAt) - +new Date(a.createdAt)
  })

  return <>
    <ul>
      { designs.length ?
          designs.map((design: DesignFragment, i: number) => {
            if (!design.id || !design.media || !design.media.length) {
              return null
            }

            return <li key={ design.id + ":" + i }>
              <input
                id={ design.id }
                type="radio"
                name="design"
                className="invisible absolute peer"
                onChange={ () => onChange(design.id) } />
              <label
                htmlFor={ design.id }
                className="
                  p-4 peer-checked:bg-blue-100 relative cursor-pointer
                  grid grid-cols-12"
              >

                <h2 className="col-span-3 text-xl">{ formatDate(design.createdAt) } Book</h2>
                <div className="col-span-9">
                  <MediaStack media={ design.media || null } />
                </div>
              </label>
            </li>
        })
      :
        <div className="p-2 text-center">Your account doesn’t have any designs yet.</div>
      }
  </ul>
  </>
}
