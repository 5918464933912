import React, { useState } from 'react'
import type { ChangeEvent } from 'react'
import { useMutation } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'

import Button from '../components/Button'
import LoginModal from '../components/LoginModal'
import NewDesignOptions from '../components/NewDesignOptions'
import useSteps, { LocalFlow, OrientationStep } from '../utils/Steps'
import { UpdateOrientationDocument } from '../graphql/__generated__'
import type { DesignFragment } from '../graphql/__generated__'

type Orientation = "horizontal" | "vertical"

export default function OrientationSelect() {
  const { designId } = useParams()
  const { nextPath, currentPath, navigateNext } = useSteps(LocalFlow, OrientationStep, { designId })
  const [attached, setAttached] = useState<boolean | undefined>(undefined)
  const [orientation, setOrientation] = useState<Orientation | undefined>(undefined)
  const navigate = useNavigate()

  const [updateOrientation, { loading, error }] = useMutation(UpdateOrientationDocument, {
    onCompleted: () => navigateNext({}),
    onError: (error: ApolloError) => {
      console.error("Error updating orientation", error)
    },
  })

  const changeAttached = (e: ChangeEvent<HTMLInputElement>) => {
    setAttached(e.target.value === 'true')
  }

  const changeOrientation = (e: ChangeEvent<HTMLInputElement>) => {
    setOrientation(e.target.value as Orientation)
  }

  const save = () => {
    let o: string | undefined = undefined
    if (attached) {
      o = orientation
    }

    updateOrientation({
      variables: {
        designId,
        orientation: o,
      }
    })
  }

  return <>
    { !designId &&
      <NewDesignOptions
        orderId=""
        onCreate={ (design: DesignFragment) => {
          navigate(currentPath({ designId: design?.id }))
        }}
      /> }

    { designId &&
      <div className="flex flex-col">
        <h1 className="text-2xl">Is your book already assembled?</h1>
        <p className="pt-1 pb-6">We generally ship empty video books with the cover not attached, so it may be attached by
        you in the best possible orientation for your unique photos and videos. Is your cover attached
        to your book already?</p>

        <ul>
          <li>
            <input
              name="attached"
              id="attached-false"
              type="radio" value="false"
              onChange={ changeAttached }
              className="invisible absolute peer" />
            <label
              htmlFor="attached-false"
              className="
                block w-full my-2 p-4 text-lg bg-cover bg-center bg-no-repeat bg-clip-padding	cursor-pointer
                outline outline-solid outline-transparent outline-2 shadow rounded
                peer-checked:outline-pink-300 peer-checked:shadow-[0_0_10px_#fab1aa]"
            >No, my cover and book are not attached yet.</label>
          </li>
          <li>
            <input
              name="attached"
              id="attached-true"
              type="radio" value="true"
              onChange={ changeAttached }
              className="invisible absolute peer" />
            <label
              htmlFor="attached-true"
              className="
                block w-full my-2 p-4 text-lg bg-cover bg-center bg-no-repeat bg-clip-padding	cursor-pointer
                outline outline-solid outline-transparent outline-2 shadow rounded
                peer-checked:outline-pink-300 peer-checked:shadow-[0_0_10px_#fab1aa]"
            >Yes, my cover is already attached to my book.</label>
          </li>
        </ul>
  

        { attached &&
          <>
            <h1 className="text-2xl py-2 mt-6">What is the orientation of your book?</h1>

            <ul>
              <li>
                <input
                  name="orientation"
                  id="orientation-horizontal"
                  type="radio" value="horizontal"
                  onChange={ changeOrientation }
                  className="invisible absolute peer" />
                <label
                  htmlFor="orientation-horizontal"
                  className="
                    block w-full my-2 p-4 text-lg bg-cover bg-center bg-no-repeat bg-clip-padding	cursor-pointer
                    outline outline-solid outline-transparent outline-2 shadow rounded
                    peer-checked:outline-pink-300 peer-checked:shadow-[0_0_10px_#fab1aa]"
                >Horizontal</label>
              </li>
              <li>
                <input
                  name="orientation"
                  id="orientation-vertical"
                  type="radio" value="vertical"
                  onChange={ changeOrientation }
                  className="invisible absolute peer" />
                <label
                  htmlFor="orientation-vertical"
                  className="
                    block w-full my-2 p-4 text-lg bg-cover bg-center bg-no-repeat bg-clip-padding	cursor-pointer
                    outline outline-solid outline-transparent outline-2 shadow rounded
                    peer-checked:outline-pink-300 peer-checked:shadow-[0_0_10px_#fab1aa]"
                >Vertical</label>
              </li>
            </ul>
          </>
        }

        { error &&
          <div className="p-2 my-4 bg-red-100">Error updating orientation, please try again.</div>
        }

        <Button
          onClick={ save }
          disabled={ orientation === undefined && attached !== false }
          active={ loading }
          className="primary mt-4 fit-content self-end">
          Next
        </Button>
      </div>
    }

    <LoginModal />
  </>
}
