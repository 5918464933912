import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'

import { DesignDocument } from '../graphql/__generated__'
import type { RenderFragment } from '../graphql/__generated__'
import useSteps, { LocalFlow, DownloadStep } from '../utils/Steps'

export default function DownloadDesign() {
  const { designId } = useParams()
  const { nextPath } = useSteps(LocalFlow, DownloadStep, { designId })

  const { data, loading, error } = useQuery(DesignDocument, {
    variables: {
      designId,
    },
    onError: (err: ApolloError) => {
      console.error("Error loading design for download design", err)
    },
  })
  const design = data?.design

  // TODO: Make this correct
  const orientation = "horizontal"

  const render = design.renders.find((render: RenderFragment) =>
    render.formFactor === 'square' && render.task === 'normalize' && orientation === 'horizontal'
  )

  return <>
    <h1>Download Design</h1>

    { render.signedURL
    ?
    <>
      <p>
        Download your design to your computer so you may load it onto your book.
        Please take note of where you save the file.
      </p>
      <a
        href={ render.signedURL }
        download
        target="_blank"
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        rel="noopener noreferrer"
      >Download</a>
    </>
    :
    <>
      <CircularProgress />
      <p>{ render.encodeProgress }%</p>
      <p>Your book is being rendered, this may take several minutes.</p>
    </>
    }
  </>
}
