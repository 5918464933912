import React from 'react'
import { useFormContext } from 'react-hook-form'
import type { Maybe, Message, Destination } from '../graphql/__generated__'

export type MessageFormFields = {
  giftMessage: Message,
}

type GiftMessageInputProps = {
  message: Maybe<Message>,
}

export default function GiftMessageInput({ message }: GiftMessageInputProps) {
  const { register } = useFormContext<MessageFormFields>();

  return (
    <div className="shadow overflow-hidden sm:rounded-md">
      <div className="px-4 py-5 bg-white sm:p-6">
        <div className="flex flex-col">
          <label className="block text-sm font-medium text-gray-700" htmlFor="giftMessage.body">
            Gift Message
          </label>
          <textarea
            {...register("giftMessage.body")}
            className="
              mt-1 block w-full h-24 p-2 text-sm
              text-gray-700 border border-gray-300 rounded-md"
            placeholder="Enter a message for the recipient"
            defaultValue={ message?.body || '' }
          />
        </div>
      </div>
    </div>
  )
}
