import React, { useState, useEffect, useCallback } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import type { ApolloError } from '@apollo/client'
import { useMutation } from '@apollo/client'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { ChevronDownIcon } from '@heroicons/react/solid'

import type { MediaEntryFragment, ClipFragment } from '../graphql/__generated__'
import { EditMediaDocument } from '../graphql/__generated__'
import VolumeSlider from './VolumeSlider'
import MediaEditorPlayer from './MediaEditorPlayer'
import SynchronizedMusicPlayer from './SynchronizedMusicPlayer'

export type MediaEditorProps = {
  media: MediaEntryFragment
  designId: string
  height?: string
  setSaving?: (isSaving: boolean) => void

  allMedia?: MediaEntryFragment[]
}

export default function MediaEditor({ designId, media, height, setSaving, allMedia }: MediaEditorProps){
  // We add one to the duration because we only store whole-number seconds but
  // need the timeline to let you select all the way to the end.
  const durationMs = media.durationSeconds ? (media.durationSeconds + 1) * 1000 : 0

  const [changed, setChanged] = useState(false)
  const [mediaVolume, setMediaVolume] = useState(media.mediaVolume || 0.6)
  const [backgroundVolume, setBackgroundVolume] = useState(media.backgroundVolume || 0.6)
  const [clips, setClips] = useState<ClipFragment[]>([])
  const [currentTimeMs, setCurrentTimeMs] = useState<number>(0)
  const [playing, setPlaying] = useState<boolean>(false)

  // Handle the media not being loaded yet:
  useEffect(() => {
    if (media.clips?.length) {
      setClips(media.clips)
    } else {
      setClips([{startMs: 0, endMs: durationMs, rotation: 0}])
    }
  }, [media.clips])

  useEffect(() => {
    setMediaVolume(media.mediaVolume)
  }, [media.mediaVolume])
  useEffect(() => {
    setBackgroundVolume(media.backgroundVolume)
  }, [media.backgroundVolume])

  const [editMedia, { loading, error }] = useMutation(EditMediaDocument, {
    onError: (_error: ApolloError) => {
      console.error("Error editing media", _error)
    },
  })

  useEffect(() => {
    if (changed) {
      save()
    }
  }, [clips, mediaVolume, backgroundVolume])

  useEffect(() => {
    setSaving && setSaving(loading)
  }, [loading])

  const save = useCallback(() => {
    editMedia({
      variables: {
        designId,
        mediaEntryId: media.id,
        media: {
          clips,
          mediaVolume,
          backgroundVolume,
        },
      },
      context: {
        debounceKey: 'media-editor',
        debounceTimeout: 1000,
      },
      onCompleted: () => {
        setChanged(false)
      },
    })
  }, [clips, mediaVolume, backgroundVolume, editMedia, designId, media.id])

  useBeforeunload((event) => {
    if (changed) {
      event.preventDefault()
    }
  });

  const icon = <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M14.121 14.121L19 19m-7-7l7-7m-7 7l-2.879 2.879M12 12L9.121 9.121m0 5.758a3 3 0 10-4.243 4.243 3 3 0 004.243-4.243zm0-5.758a3 3 0 10-4.243-4.243 3 3 0 004.243 4.243z" />
  </svg>

  return <div className="flex grid grid-cols-6 gap-2">
    <div className="md:col-span-2 col-span-6">
      <Accordion defaultExpanded={ true }>
        <AccordionSummary expandIcon={ <ChevronDownIcon className="w-8 h-8" /> } className="!bg-gray-50">
          <h2 className="text-lg font-bold">How to Edit Your Video</h2>
        </AccordionSummary>
        <AccordionDetails>
          <div className="text-sm p-2">
            <p className="pt-2">
              You can use this page to trim the length of your video clip.
            </p>
            <p className="pt-2">
              Only the portion of the video between the green and red markers
              will be included on your video book. Drag the markers to adjust
              when your clip starts and ends.
            </p>
            { clips[clips.length - 1] && clips[clips.length - 1].endMs + 4000 < durationMs
              ?  <p className="pt-2">
                  Click { icon } to add another portion of your video to your video book.
                </p>
              : <p className="pt-2">
                  Move the last red marker left if you wish to add another clipped section to your video book.
                </p>
            }
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={ <ChevronDownIcon className="w-8 h-8" /> } className="!bg-gray-50">
          <h2 className="text-lg font-bold">Audio Options</h2>
        </AccordionSummary>
        <AccordionDetails>
          <div className="text-sm p-2 mt-2">
            <p className="pt-2">
              You can adjust the volume of this clip's sound, and any
              background music which will play behind this clip.
            </p>

            <label className="block mt-4 font-bold">Video Volume</label>
            <VolumeSlider
              volume={ mediaVolume }
              onChange={ (val) => { setMediaVolume(val); setChanged(true) } }
              ariaLabel="Video volume"
            />

            <label className="block mt-2 font-bold">Background Music Volume</label>
            <VolumeSlider
              volume={ backgroundVolume }
              onChange={ (val) => { setBackgroundVolume(val); setChanged(true) } }
              ariaLabel="Background music volume"
            />
          </div>

          { error && <p className="mt-2 bg-red-200 p-2">Error saving, please try making your change again.</p> }
        </AccordionDetails>
      </Accordion>
    </div>
    <div className="md:col-span-4 col-span-6">
      <MediaEditorPlayer
        media={ media }
        clips={ clips }
        setClips={ (val) => { setClips(val); setChanged(true) } }
        mediaVolume={ mediaVolume }
        durationMs={ durationMs }
        height={ height }
        setPlaying={ setPlaying }
        setCurrentTimeMs={ setCurrentTimeMs }
      />

      { allMedia &&
        <SynchronizedMusicPlayer
          media={ allMedia }
          syncToMediaId={ media.id }
          gain={ 1 }
          playing={ playing }
          currentTimeMs={ currentTimeMs } />
      }
    </div>
  </div>
}
