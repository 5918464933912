import React from 'react'

import MediaStack from './MediaStack'
import QuantityForm from './QuantityForm'
import AddressSummary from './AddressSummary'
import Button from './Button'

import type { OrderFragment, DesignFragment, DestinationFragment, DesignWithDestinationsFragment } from '../graphql/__generated__'

type DestinationSummaryListingProps = {
  order: OrderFragment,
  componentName: string,
  onEdit: (dest: DestinationFragment) => void,
  onAdd: (design: DesignFragment) => void,
  onCancel: () => void,
  currentlyEditing: DestinationFragment | null,
}

export default function DestinationSummaryListing({ order, onEdit, onAdd, onCancel, currentlyEditing, componentName }: DestinationSummaryListingProps) {
  const multipleDesigns = order?.designs && order.designs.length > 1

  return <>
    { order?.designs && order.designs.map((design: DesignWithDestinationsFragment, i: number) => <>
        { multipleDesigns &&
          <div>
            <h1>Design { i + 1 }</h1>
            <div className="hidden md:block col-span-9">
              <MediaStack media={ design.design?.media || null } />
            </div>
          </div>
        }

        <ul className="mt-12">
          <li key="header">
            <div className="mx-auto my-2 flex justify-center font-bold">
              <div className="w-36">
                Quantity
              </div>
              <div className="w-72">
                Address
              </div>
              <div className="w-8"></div>
            </div>
          </li>

          { design.destinations?.map((destination: DestinationFragment) => {
            const isEditing = currentlyEditing?.id === destination.id

            return <li key={ destination.id } className="relative">
              { typeof design.design?.id === 'string' &&
                <>
                  <div className={ "mx-auto my-2 flex justify-center items-center" + (isEditing ? " blur" : "") }>
                    <div className="w-36">
                      <QuantityForm
                        destination={ destination }
                        order={ order }
                        designId={ design.design?.id }
                      />
                    </div>
                    <div className="w-72">
                      <AddressSummary address={ destination?.address || null } />
                    </div>
                    <div className="w-8">
                      <Button
                        className="w-[8rem]"
                        onClick={ () => onEdit(destination) }
                      >Edit { componentName }</Button>
                    </div>

                  </div>
                  { isEditing && <div className="absolute top-0 left-0 right-0 bottom-0
                                                flex flex-row justify-center items-center">
                      <div className="mr-4 w-[29rem] text-center">Editing this at the bottom of the page.</div>
                      <Button
                        className="w-[5rem]"
                        type="secondary"
                        onClick={ onCancel }
                      >Cancel</Button>
                    </div>
                  }
                </>
              }
            </li>
          }) }
        </ul>

        { !currentlyEditing && design?.design
          ? <>
            <Button
              type="secondary"
              className="align-center mt-2"
              onClick={ () => design.design && onAdd(design.design) || undefined }>
              Add Another
            </Button>
          </>
          : <div />
        }
    </>)}
  </>
}
