import React, { useState, useEffect } from 'react'
import { makeVar, useReactiveVar } from '@apollo/client'
import { useNavigate, useLocation } from 'react-router-dom'

export type StepArgs = {[key: string]: string | boolean | undefined} & {
  orderId?: string
  designId?: string
  destinationId?: string
}

export type PathFn = (args: StepArgs) => string

const NullPathFn: PathFn = () => ''

export type Step = {
  name: string
  status?: string
  default?: boolean
  showInNav?: boolean
  path: PathFn
}

export type NavigateOptions = {
  returnHere?: boolean,
}

function generateDestPath(name: string, { orderId, designId, destinationId, add }: StepArgs){
  let out = `/order/${ orderId }`
  if (designId) {
    out += `/design/${ designId }`
  }
  if (destinationId) {
    out += `/destination/${ destinationId }`
  }
  if (name !== '') {
    out += `/${ name }`
  }
  if (add) {
    out += `?add=true`
  }
  return out
}

export const CoverStep: Step = {
  name: 'Cover',
  path: (options: StepArgs) => generateDestPath('cover', options),
}

export const MediaStep: Step = {
  name: 'Media',
  path: (options: StepArgs) => generateDestPath('', options),
}

export const AddressStep: Step = {
  name: 'Address',
  path: (options: StepArgs) => generateDestPath('address', options),
}

export const MessageStep: Step = {
  name: 'Message',
  path: (options: StepArgs) => generateDestPath('message', options),
}

export const ReviewStep: Step = {
  name: 'Review',
  default: true,
  path: ({ orderId }) => `/order/${orderId}`,
}

export const CheckoutStep: Step = {
  name: 'Checkout',
  path: ({ orderId }) => `/order/${orderId}/payment`,
}

export const LocalWelcomeStep: Step = {
  name: 'Welcome',
  path: () => `/local`,
  showInNav: false,
}

export const LocalMediaStep: Step = {
  name: 'Media',
  path: ({ designId }) => `/local/design/${designId}/media`
}

export const OrientationStep: Step = {
  name: 'Orientation',
  path: ({ designId }) => {
    let out = `/local/design`
    if (designId) {
      out += `/${designId}`
    }
    return out
  }
}

export const AssembleStep: Step = {
  name: 'Assemble',
  path: ({ designId }) => `/local/design/${ designId }/assemble`
}

export const DownloadStep: Step = {
  name: 'Download',
  path: ({ designId }) => `/local/design/${designId}/download`,
}

export const TransferStep: Step = {
  name: 'Transfer',
  path: ({ designId }) => `/local/design/${designId}/transfer`,
}

export const BuilderFlow: Step[] = [
  CoverStep,
  MediaStep,
  AddressStep,
  MessageStep,
  ReviewStep,
  CheckoutStep,
]

export const LocalFlow: Step[] = [
  LocalWelcomeStep,
  OrientationStep,
  LocalMediaStep,
  AssembleStep,
  DownloadStep,
  TransferStep,
]

export const FLOWS: Step[][] = [
  BuilderFlow,
  LocalFlow,
]

const CURRENT_STEP = makeVar<Step | null>(null)
const CURRENT_FLOW = makeVar<Step[] | null>(null)
const CURRENT_STATE = makeVar<StepArgs | null>(null)
const RETURN_TO = makeVar<Step | null>(null)

const isEqual = (a: StepArgs, b: StepArgs) => a.orderId === b.orderId && a.designId === b.designId && a.destinationId === b.destinationId

export default function useSteps(myFlow?: Step[], myStep?: Step, myState?: StepArgs){
  const navigate = useNavigate()
  const location = useLocation()
  const currentStep = useReactiveVar(CURRENT_STEP)
  const currentFlow = useReactiveVar(CURRENT_FLOW)
  const currentState = useReactiveVar(CURRENT_STATE)
  const returnTo = useReactiveVar(RETURN_TO)

  useEffect(() => {
    CURRENT_STEP(myStep || null)
    CURRENT_FLOW(myFlow || null)

    // @ts-ignore TS Doesn't see the CURRENT_STATE not-null check
    if (!myState || !CURRENT_STATE() || !isEqual(myState, CURRENT_STATE())) {
      CURRENT_STATE(myState || null)
    }
  }, [myStep, myFlow, myState])

  let steps: Step[] | null = null
  if (currentFlow){
    steps = currentFlow.slice()
    let foundCurrentStep = false
    for (let i = 0; i < currentFlow.length; i++) {
      if (steps[i].name === currentStep?.name) {
        steps[i].status = 'current'
        foundCurrentStep = true
      } else if (foundCurrentStep) {
        steps[i].status = 'upcoming'
      } else {
        steps[i].status = 'complete'
      }
    }
  }

  const path = (step: Step, args: StepArgs={}) => step.path(Object.assign({}, currentState, args)) || ''

  let nextPath: PathFn = NullPathFn
  let prevPath: PathFn = NullPathFn
  let currentPath: PathFn = NullPathFn
  let nextStep: Step | undefined = undefined
  let prevStep: Step | undefined = undefined
  if (currentStep && currentFlow) {
    const stepIndex = currentFlow?.findIndex(step => step.name === currentStep?.name)
    nextStep = currentFlow?.[stepIndex + 1]
    prevStep = currentFlow?.[stepIndex - 1]

    if (returnTo) {
      // The person who sent us here informed us we should actually go to a
      // specific next step (likely back to them).
      nextStep = returnTo
    }

    if (nextStep) {
      // @ts-ignore TS doesn't see the existance check above
      nextPath = (args: StepArgs) => path(nextStep, args)
    }
    if (prevPath) {
      // @ts-ignore TS doesn't see the existance check above
      prevPath = (args: StepArgs) => path(prevStep, args)
    }

    currentPath = (args: StepArgs) => path(currentStep, args)
  }

  const navigateNext = (args: StepArgs) => {
    if (nextPath) {
      const path = nextPath(args)
      if (path) {
        navigate(path)
      }
    }
  }

  const navigatePrev = (args: StepArgs) => {
    if (prevPath) {
      const path = prevPath(args)
      if (path) {
        navigate(path)
      }
    }
  }

  const navigateTo = (step: Step, args: StepArgs, options: NavigateOptions) => {
    if (options.returnHere) {
      RETURN_TO(currentStep)
    }

    navigate(step.path(Object.assign({}, currentState, args)))
  }

  return {
    currentStep,
    currentFlow,
    steps,
    path,
    nextPath,
    prevPath,
    currentPath,
    navigateNext,
    navigatePrev,
    navigateTo,
    hasReturn: !!returnTo,
    returnStep: returnTo,
  }
}
