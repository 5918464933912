import React, { useRef, useEffect, useState } from 'react'
import type { SyntheticEvent } from 'react'
import Hls from 'hls.js'

import { useQuery } from '@apollo/client'
import { PreviewDocument } from '../graphql/__generated__'
import type { ApolloError } from '@apollo/client'

import Loader from './Loader'
import Button from './Button'

export type PreviewProps = {
  designId: string
  generation: string
}

export default function Preview({ designId, generation }: PreviewProps) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [timedOut, setTimedOut] = useState(false)

  const { data, loading, error, refetch } = useQuery(PreviewDocument, {
    variables: {
      designId,
    },
    onError: (err: ApolloError) => {
      console.error("Error loading preview", err)
    }
  })

  useEffect(() => {
    refetch()
  }, [generation])

  useEffect(() => {
    if (!data?.designPreview?.playlistURL || !videoRef.current){
      return
    }

    const url = data.designPreview.playlistURL
    const video = videoRef.current

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.attachMedia(video);
      hls.loadSource(url);
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = url;
    }
  }, [videoRef, data?.designPreview?.playlistURL])

  let timer: ReturnType<typeof setTimeout> | undefined
  const onError = (e: SyntheticEvent) => {
    console.error("Error loading preview", e)
    timer && clearTimeout(timer)
  }
  const didLoad = () => {
    timer && clearTimeout(timer)
  }
  const retry = () => {
    setTimedOut(false)
    refetch()
  }

  useEffect(() => {
    setTimedOut(false)
    timer = setTimeout(() => {
      console.error("Preview timed out")
      setTimedOut(true)
    }, 20000)
    return () => timer && clearTimeout(timer)
  }, [videoRef, data?.designPreview?.playlistURL])

  if (loading) {
    return <Loader description="Generating Preview..." />
  }

  if (error || timedOut) {
    return <div className="my-2 p-2 bg-red-100 m-auto font-bold text-center">
      Error generating preview, please try again.

      { !loading && <Button
        className="block mt-2 mx-auto"
        onClick={ () => { retry() } }
      >Retry</Button> }
    </div>
  }

  if (!data) {
    return <></>
  }

  return <div className="relative bg-gray m-auto">
    <video ref={ videoRef } controls autoPlay
      onError={ onError }
      onCanPlay={ didLoad }
      style={{ width: 'min(427px, 100%)' }}
      className="m-auto max-h-[427px] rounded-lg">
         <source type="application/x-mpegURL" />
     </video>
  </div>
}
