import React from 'react'

import { formatPrice } from '../utils/Price'
import PricingSummary from './PricingSummary'
import type { OrderFragment } from '../graphql/__generated__'

type OrderPricingSummaryProps = {
  order: OrderFragment
}

export default function OrderPricingSummary({ order }: OrderPricingSummaryProps) {
  const cost = order?.cost
  if (!cost) {
    return <></>
  }

  const {total, byDestination} = cost

  return <PricingSummary costs={ total } />
}
