import React, { useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useQueryParams } from '../utils/Query'
import { TOKEN_VAR } from '../apollo-client'

const DAY = 24 * 60 * 60 * 1000

export default function AuthLinkLogin() {
  let query = useQueryParams()

  const token = query.get('token')
  if (token) {
    // Tokens last 30 days, we expire it early to make it very unlikely
    // that someone's token will expire during a session.
    const exp = new Date().getTime() + 28 * DAY

    TOKEN_VAR({
      token,
      expiration: exp,
    })

    localStorage.token = token
    // TODO Actually pass and check expiry, as email link could have been old.
    localStorage.tokenExpiration = exp

    return <Navigate to='/' />
  }

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 backdrop-blur flex items-center justify-center">
      <div className="max-w-md w-full space-y-8 px-4">
        <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Authentication Error</h1>
      
        <p className="font-medium text-slate-600 hover:text-indigo-500">
          Unfortunately there was an error while trying to log you in.
        </p>

        <a className="p-4 bg-indigo-400 text-white font-bold rounded-lg" href="/">
          Try again
        </a>
      </div>
    </div>
  )
}
