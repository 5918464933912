import React, { useCallback, useState, useMemo } from 'react'
import type { RefObject } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import { useBeforeunload } from 'react-beforeunload'

import Uploader from './Uploader'
import MediaListing from './MediaListing'
import MusicListing from './MusicListing'
import ModalPreview from './ModalPreview'
import Button from './Button'
import LoadingMediaManager from './LoadingMediaManager'
import MediaUsageBar from './MediaUsageBar'
import { DesignDocument, AppendMediaDocument, UpdateMediaDocument } from '../graphql/__generated__'
import type { MediaEntryFragment } from '../graphql/__generated__'

type MediaManagerProps = {
  designId: string
  dropContainer: RefObject<HTMLElement>
}

export default function MediaManager({ designId, dropContainer }: MediaManagerProps) {
  const { data, loading, error, startPolling, stopPolling } = useQuery(DesignDocument, {
    notifyOnNetworkStatusChange: true,
    variables: {
      designId,
    },
    onError: (err: ApolloError) => {
      console.error("Error loading media", err)
    },
  })

  const [appendMedia, { loading: appendSaving, error: appendSaveError }] = useMutation(AppendMediaDocument)
  const [updateMedia, { loading: updateSaving, error: updateSaveError }] = useMutation(UpdateMediaDocument)
  const [changed, setChanged] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState(false)
  const [showUpload, setShowUpload] = useState(false)

  const design = data?.design
  const media = design?.media
  const music = useMemo(() => media?.filter((m: MediaEntryFragment) => m.type === "music"), [media])
  const notMusic = useMemo(() => media?.filter((m: MediaEntryFragment) => m.type !== "music"), [media])

  type MediaQueueEntry = {
    id: string,
    onCompleted?: () => void,
  }

  let mediaQueue: MediaQueueEntry[] = []
  let mediaTimeout: ReturnType<typeof setTimeout> | null = null

  const addMedia = (id: string, onCompleted?: () => void) => {
    setChanged(true)

    mediaQueue.push({id, onCompleted})
    mediaTimeout && clearTimeout(mediaTimeout)
    setTimeout(saveMediaQueue, 250)
  }

  const saveMediaQueue = useCallback(() => {
    const savingQueue = mediaQueue.slice()
    mediaQueue = []

    appendMedia({
      variables: {
        designID: designId,
        media: savingQueue.map((entry: MediaQueueEntry) => entry.id),
      },
      onError: (err: ApolloError) => {
        console.error("Error storing new media", err)
        savingQueue.forEach((entry: MediaQueueEntry) => entry.onCompleted && entry.onCompleted())

        mediaQueue.push(...savingQueue)
      },
      onCompleted: () => {
        setChanged(false)
        savingQueue.forEach((entry: MediaQueueEntry) => entry.onCompleted && entry.onCompleted())
      },
    })
  }, [appendMedia, designId, mediaQueue])

  const removeMediaById = (id: string, whenDone?: () => void) => {
    const mediaIds = media
      .filter((m: MediaEntryFragment) => m.id !== id)
      .map((m: MediaEntryFragment) => m.id)

    saveMedia(mediaIds, whenDone)
  }

  const saveMedia = useCallback((mediaIds: string[], whenDone?: () => void) => {
    setChanged(true)

    updateMedia({
      variables: {
        designID: designId,
        media: mediaIds,
      },
      onError: (err: ApolloError) => {
        console.error("Error updating media", err)
        whenDone && whenDone()
      },
      onCompleted: () => {
        setChanged(false)

        whenDone && whenDone()
      },
      context: {
        debounceKey: 'media-manager',
        debounceTimeout: 1000,
      },
    })
  }, [updateMedia, designId, media])

  const handleUpload = useCallback((entry: {id: string}) => {
    addMedia(entry.id)
  }, [addMedia])

  useBeforeunload((event) => {
    if (changed) {
      event.preventDefault()
    }
  });

  if (media) {
    // When we add new media, poll for changes until all of our
    // media has been processed and we have all the URLs.
    // TODO Custom music currently doesn't populate these values, causing
    // infinite polling.
    let unfinishedMedia = false
    for (let i=media.length; i--;){
      if (!!!media[i].signedThumbnailLocation
          || !!!media[i].signedEncodedLocation
          || (media[i].encodeProgress && media[i].encodeProgress < 100)){
        startPolling(2000)
        unfinishedMedia = true
        break
      }
    }

    if (!unfinishedMedia) {
      stopPolling()
    }
  }

  // We memoize to prevent the polling from rerendering when nothing
  // has actually changed.
  return useMemo(() => {
    if (!media) {
      return <LoadingMediaManager />
    }

    return <>
      <Uploader
        dropContainer={ dropContainer }
        onUploaded={ handleUpload }
        showUpload={ showUpload }
        onUploadShown={ () => setShowUpload(false) }
      >
        <>
          <div>
            <MediaListing
              segments={ notMusic }
              allMedia={ media }
              designId={ designId }
              onRemove={ removeMediaById }
              onReorder={ saveMedia }
              onOpenUpload={ () => setShowUpload(true) }
            />

            { error &&
              <div className="p-2 my-2 bg-red-100">
                There was an error loading your photos and videos. Please reload the page to try again.
              </div>
            }

            { appendSaveError &&
              <div className="p-2 my-2 bg-red-100">
                There was an error adding your file. Please try again.
              </div>
            }
            { updateSaveError &&
              <div className="p-2 my-2 bg-red-100">
                There was an error updating your media. Please try again.
              </div>
            }
          </div>
        </>
      </Uploader>


      <div className="mt-16 mb-2">
        <MusicListing
          segments={ music }
          onAdd={ addMedia }
          onRemove={ removeMediaById }
        />
      </div>

      <div className="mb-[-2.5rem] grid grid-cols-3">
        <MediaUsageBar
          media={ media }
        />

        { design?.generation && <div className="flex">
          <Button
            onClick={ () => setShowPreview(true) }
            disabled={ notMusic.length === 0 }
            title={ notMusic.length === 0 ? "You must add at least one photo or video to preview your design." : "Watch a preview of your design, just as it will go onto your video book." }
            className="m-auto"
            type="tertiary"
          ><span className="md:inline hidden">Watch </span>Preview</Button>
          <ModalPreview
            show={ showPreview }
            onClose={ () => setShowPreview(false) }
            generation={ design?.generation }
            designId={ designId } />
        </div> }
      </div>
    </>
  }, [media, showPreview, showUpload, designId, updateSaveError, appendSaveError])
}
