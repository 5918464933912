import React from 'react'

import DesignSummary from './DesignSummary'
import OrderProgressBar from './OrderProgressBar'
import ButtonLink from '../components/ButtonLink'
import type { OrderFragment } from '../graphql/__generated__'
import type { DesignWithDestinationsFragment } from './OrderEdit'

type OrderSummaryProps = {
  order: OrderFragment
  showThanks: boolean
}

export default function OrderSummary({ order, showThanks }: OrderSummaryProps) {
  const showBorder = order?.designs && order.designs.length > 1

  return <>
    { order &&
      <OrderProgressBar order={order} />
    }

    { showThanks &&
      <div className="bg-gray-100 p-6 text-center space-y-4">
        <h1 className="text-xl">Thank you for your order!</h1>

        <p className="text-gray-500 text-sm">You will receive an email with your order details shortly.</p>

        <ButtonLink to="/" className="mt-4">Build another book</ButtonLink>
      </div>
    }

    <ul>
    {
      order?.designs?.map((design: DesignWithDestinationsFragment, i: number) => {
        if (design.design) {
          return <li key={ design.design.id }
            className={"flex flex-col mt-2" + (showBorder ? " p-2 border-gray-200 border-4" : "")}>

            <DesignSummary
              allowEdit={ false }
              shrink={ true }
              order={ order }
              design={ design.design }
              destinations={ design.destinations || null } />
          </li>
        }
      })
    }
    </ul>
  </>
}
