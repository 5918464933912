import React from 'react'
import { useParams } from 'react-router-dom'

import ButtonLink from '../components/ButtonLink'
import useSteps, { LocalFlow, AssembleStep } from '../utils/Steps'

export default function AssembleBook() {
  const { designId } = useParams()
  const { nextPath } = useSteps(LocalFlow, AssembleStep, { designId })

  return <>
    <h1>Assemble Your Book</h1>

    <p> Your book was delivered unattached to allow us to choose the ideal orientation
    for your screen based on your videos and photos. It's time to attach your screen to
    your book's cover.</p>

    <h2>Your Orientation is: Vertical</h2>

    <h2>Attaching Your Cover</h2>

    <h3>1. Test fit</h3>
    <p>Test placing the book onto the cover to learn how to must fit. It's critical that your
    book is aligned to ensure the cover will close properly. You may test the cover now to ensure
    you understand how the magnets must align before the next step. If the book doesn't properly stay
    closed, it's not aligned.
    </p>
    
    <h3>2. Expose the double-stick tape from the back of your screen</h3>
    <p>Peal the protective covering off the double-stick tape on the back of your video book's screen.</p>

    <h3>3. Carefully place the book onto the cover</h3>
    <p>Ensure the spacing is even on the right, top, and bottom edges before pressing the book down.</p>

    <h3>4. Press firmly on the edges of the book</h3>
    <p>Pressing firmly will ensure the book is permanently attached to the cover. DO NOT press on the screen,
    it can be damaged.</p>

    <ButtonLink
      to={ nextPath({}) }
      className="primary">Next</ButtonLink>
  </>
}
