import React from 'react'

import Button from '../components/Button'
import useSteps from '../utils/Steps'

export default function Help() {
  // Reset to hide steps in nav
  useSteps()

  return <div className="flex flex-col items-center">
    <div className="bg-gray-50 p-6 w-full flex flex-col items-center">
      <p className="mb-6">Search our knowledge base to find answers to the most commonly asked questions.</p>
      <form
        target="_blank"
        action="https://help.sendheirloom.com/search"
        method="GET"
        autoComplete="off">

        <input
          type="text"
          name="query"
          className="
            text-3xl w-[16em] h-16 focus:outline-0 focus:box-shadow-0
            border-pink-300 border-2 focus:border-gray-500 rounded-l
            "
          title="search-query"
          placeholder="Search our knowledge base"
          aria-labelledby="Search our knowledge base" />

        <Button
          type="secondary"
          className="text-3xl rounded-l-none h-16">Search</Button>
      </form>
    </div>

    <div className="mt-2 bg-gray-50 p-6 w-full flex flex-col items-center">
      <p className="mb-6">Get more help by sending us an email.</p>
      <a
        className="text-5xl text-pink-300 font-bold"
        href="mailto:help@sendheirloom.com">help@sendheirloom.com</a>
    </div>
  </div>
}
