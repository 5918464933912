import React from 'react'
import type { Address, Maybe } from "../graphql/__generated__"

type AddressSummaryProps = {
  address: Maybe<Address>
}

export default function AddressSummary({ address }: AddressSummaryProps) {
  if (!address) {
    return <></>
  }

  return (
    <div>
      <div>{ address.fullName }</div>
      <div>{ address.street1 }</div>
      { address.street2 && <div>{ address.street2 }</div> }
      <div>{ address.cityLocality }, { address.stateProvinceCode } { address.postalCode }</div>
      { address.countryCode !== 'US' && <div>{ address.countryCode }</div> }
    </div>
  )
}
