import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import CircularProgress from '@mui/material/CircularProgress'
import { API_HOST, isAuthenticated, useAuthentication } from '../apollo-client'
import { useNavigate } from 'react-router-dom'

type LoginData = {
  email: string
}

export default function LoginModal() {
  const navigate = useNavigate()

  let [loggingIn, setLoggingIn] = useState(false)
  let [loginError, setLoginError] = useState(false)
  let [loginDone, setLoginDone] = useState(false)

  async function login(data: LoginData) {
    setLoggingIn(true)
    setLoginError(false)
    localStorage.setItem('email', data.email)

    let resp: Response|undefined
    try {
      resp = await fetch(API_HOST + "/auth/by-email", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      })
    } catch (err) {
      console.error('Login error', err)
      setLoginError(true)
    }

    setLoggingIn(false)

    if (resp) {
      if (resp.status < 300) {
        setLoginDone(true)
        const body = await resp.json()
        if (body.token) {
          // This was a new email, never registered, so we got back a token immediately.
          navigate(`/auth/link?token=${ body.token }`)
        }
      } else {
        console.error('Bad status from login', resp.status)
        setLoginError(true)
      }
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const { isAuthenticated } = useAuthentication()

  if (isAuthenticated) {
    return <></>
  }

  const form = <>
    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Let's Get Started</h1>
    <p className="font-medium text-slate-600 hover:text-indigo-500">Before we begin building your book, please provide us
      with your email address so your work is not lost.</p>

    <p className="font-medium text-slate-600 hover:text-indigo-500">Already working on a book? Enter your email address and we'll find it for you.</p>

    <form className="mt-8 space-y-3" onSubmit={ handleSubmit(login) }>
      <div className="rounded-md shadow-sm -space-y-px">
        <div>
          <label htmlFor="email-address" className="sr-only">Email address</label>
          <input id="email-address" type="email" autoComplete="email" required
            {...register('email', { required: true })}
            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-lg sm:text-md" placeholder="Email address" />
        </div>
      </div>
      { errors.email && <p className="py-2 text-red-700">Please enter a valid email address.</p> }
      { loginError && <p className="py-2 text-red-700">There was an error logging you in. Please try again.</p> }

      <div>
        <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
            </svg>
          </span>
          { loggingIn ?
            <CircularProgress />
            : <>Continue</>
          }
        </button>
      </div>
    </form>
  </>

  return <>
    <div className="fixed top-0 left-0 right-0 bottom-0 z-50
                    backdrop-blur flex items-center justify-center">
      <div className="max-w-md w-full space-y-8 px-4">

        { loginDone ?
          <>
            <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Login Link Sent</h1>
            <p>We have sent a login link to your email address. Be sure to check your spam folder.</p>
            <p>If you don't receive it in the next several minutes, please&nbsp;
              <a onClick={ () => setLoginDone(false) } className="text-indigo-500 hover:text-indigo-600 cursor-pointer">try again</a>
              &nbsp;or <a className="text-indigo-500 hover-text-indigo-600 cursor-pointer" href="mailto:help@sendheirloom.com">contact us</a>.
            </p>
          </>
        :
          form
        }

      </div>
    </div>
  </>
}
