import type { UseFormSetValue } from 'react-hook-form'
import type { Address } from '../graphql/__generated__'
import countries from '../utils/countries.json'
import type { AddressFormFields } from '../components/AddressForm'

export const API_KEY="AIzaSyC2jXRfNnrkNi1FPKyUR_MksNafccVpG9s"

type Normalizer = (stateName: string) => string
// The mapping between Google's understanding of short names and ours is
// also not perfect, for example they call Beijing's state Bei Jing Shi, where
// we call it Beijing or BN. Similarly, Google will return England rather than
// any of the state prefixes.
//
// It's a bit odd that we search all countries rather than just the one selected,
// but this will work until someone finds a state who's legitimate name is the
// same as a prefix of a state in another country.
function replaceStateWithCode(stateName: string): string {
  let out = stateName
  countries.some(({states}) => {
    return states.some(({code, name}) => {
      if (name.toLowerCase() === stateName.toLowerCase()) {
        out = code
        return true
      } else if (stateName === 'Bei Jing Shi') {
        // One special case we know about
        // In the future we could grab the first two captialized chars,
        // as that seems to be the code for most provinces.
        return 'BJ'
      }

      return false
    })
  })

  return out
}

// Order matters here, as some countries will use area_level_2 as what
// we think of as a city (like India), others use locality (like US).
// This is in reverse order of precedence, with subsequent values overwriting
// previous values.
//
// Google specifies the street number and street name seperately, we use a
// '+' to signify that we want to suffix the value.
const fieldMapping: Array<[string, keyof Address, Normalizer?]> = [
  ['street_number', 'street1'],
  ['route+', 'street1'],
  ['administrative_area_level_1', 'stateProvinceCode', replaceStateWithCode],
  ['administrative_area_level_2', 'cityLocality'],
  ['sublocality', 'street2'],
  ['locality', 'cityLocality'],
  ['postal_town', 'cityLocality'],
  ['country', 'countryCode'],
  ['postal_code', 'postalCode'],
]

type PlaceResult = google.maps.places.PlaceResult

export function mapPlaceToAddressForm(setValue: UseFormSetValue<AddressFormFields>, {address_components}: PlaceResult){
  const toWrite:  Map<keyof Address, Array<string>> = new Map()

  for (let i=0; i < fieldMapping.length; i++){
    let [field, component, normalizer] = fieldMapping[i]

    let suffix = false
    if (field[field.length - 1] === '+') {
      suffix = true
      field = field.substring(0, field.length - 1)
    }

    address_components?.forEach(({short_name, types}) => {
      if (types.includes(field)){
        if (typeof normalizer !== 'undefined') {
          short_name = normalizer(short_name)
        }

        const existing = toWrite.get(component)
        if (typeof existing !== 'undefined'){
          if (suffix) {
            toWrite.set(component, [...existing, short_name])
            return
          }
        }

        toWrite.set(component, [short_name])
      }
    })
  }

  toWrite.forEach((value, key) => setValue(`address.${ key }`, value.join(' ')))
}
