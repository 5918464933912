import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import type { ApolloError } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'
import { Elements, PaymentElement, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { OrderForPaymentDocument, OrderFragment } from '../graphql/__generated__'
import Button from './Button'

let stripePromise: Promise<any> | null = null
if (localStorage.DEBUG_stripe_test || true){
  stripePromise = loadStripe('pk_test_51JPwtdAHAhN8nuhgoOcZtasegdGpbhZMlCKPdmFy5TxDpVhtMIIDLlu3uZdvi5MoNH7JXsKC3LkstaKHN9cQRfxL000gpQa6U7')
} else {
  stripePromise = loadStripe('pk_live_51JPwtdAHAhN8nuhgn3MvHmOe3dya0HLjA8UH1PuvF83vWQfG2vjscPXl07fnca1GOqSrjMP0DxNJ6zorBnvn39We00UF94fL3X')
}

type PaymentFormInputProps = {
  order: OrderFragment,
  nextPath: string,
}

export type PaymentFormInputRef = {
  submit: () => void,
}

const PaymentFormInput = forwardRef(({ order, nextPath }: PaymentFormInputProps, ref) => {
  const [stripeError, setStripeError] = useState<string | null>(null)
  const [loadOrderForPayment, { data, loading, error }] = useLazyQuery(OrderForPaymentDocument, {
    variables: {
      orderId: order?.id,
    },
    onError: (err: ApolloError) => {
      console.error("Error loading order for payment intent id", err)
    },
  })
  const paymentIntent = data?.orderStatus?.paymentIntent
  const cost = order?.cost?.total.total.actualInCents

  // Using useElements requires the component to be wrapped in an Elements element,
  // so we use a second inner component.
  function InnerPaymentElement(){
    const elements = useElements()
    // TODO A component shouldn't really be navigating us
    const navigate = useNavigate()

    useImperativeHandle(ref, () => ({
      submit() {
        handleSubmit()
      }
    }))

    if (!order?.cost) {
      return <></>
    }

    const handleSubmit = async () => {
      const stripe = await stripePromise
      if (!stripe || !elements) {
        return
      }

      const result = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: document.location.origin + nextPath,
        },
      })

      if (result.error) {
        console.error("Payment error", result.error.message)
        setStripeError(result.error.message)
      } else {
        // The customer will be HTTP redirected IFF the payment method required it,
        // otherwise this code path will run.
        navigate(nextPath)
      }
    }

    return <PaymentElement />
  }

  useEffect(() => {
    if (cost) {
      loadOrderForPayment()
    }
  }, [cost])

  if (loading) {
    return <div className="flex justify-center"><CircularProgress /></div>
  }
  if (error) {
    return <div className="p-2 my-4 bg-red-200">Error loading payment options.</div>
  }
  if (!order || !paymentIntent) {
    return <></>
  }

  const stripeOptions = {
    clientSecret: paymentIntent?.clientSecret,
  }
  
  return <>
    <Elements stripe={ stripePromise } options={ stripeOptions }>
      <InnerPaymentElement />
    </Elements>

    { stripeError && <div className="p-2 my-4 bg-red-200">Error processing your payment, please try again.</div> }
  </>
})

export default PaymentFormInput
