import React from 'react'
import type { ChangeEvent } from 'react'

import { useQuery, useMutation } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'
import type { ApolloError } from '@apollo/client'

import { ShippingOptionsDocument, UpdateShippingChoiceDocument, OrderForPaymentDocument } from '../graphql/__generated__'
import type { ShippingOptionFragment, ShippingChoice, DestinationFragment, OrderFragment } from '../graphql/__generated__'
import { formatPrice, resetCost } from '../utils/Price'

type ShippingOptionsFormProps = {
  order: OrderFragment
  destination: DestinationFragment
  onSubmit?: (shippingOptionId: string) => void
}

export default function ShippingOptionsForm({ order, destination }: ShippingOptionsFormProps) {
  const [updateShippingChoice, { loading: saving, error: saveError }] = useMutation(UpdateShippingChoiceDocument, {
    // We need to let Apollo know that the cost of the order will have changed,
    // so it can be refetched.
    update(cache) {
      resetCost(cache, order)
    },
  })

  const { loading, error, data } = useQuery(ShippingOptionsDocument, {
    variables: {
      destinationId: destination.id,
    },
    onError: (error: ApolloError) => {
      console.error("Error loading shipping options", error)
    },

    // This is necessary because our attempt to force this to refetch when the address
    // is changed was unsuccessful. We cache rates on the server, so it's not much
    // trouble to refetch.
    fetchPolicy: 'no-cache'
  })

  function saveChange(e: ChangeEvent<HTMLInputElement>) {
    const [_type, val] = e.target.value.split('::')

    let choice: ShippingChoice = {}
    if (_type === 'code') {
      choice.serviceCode = val
    } else if (_type === 'speed') {
      choice.shippingSpeed = val
    } else {
      throw new Error(`Unknown type ${_type}`)
    }

    updateShippingChoice({
      variables: {
        destinationId: destination.id,
        choice,
      },
      onError: (error: ApolloError) => {
        console.error("Error saving shipping choice", error)
      },
      refetchQueries: [OrderForPaymentDocument],
    })
  }

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <div className="p-2 bg-red-300">Error loading shipping rates. Please confirm that the address is valid.</div>
  }

  let options: Array<ShippingOptionFragment> = data?.shippingOptions || []
  options = [...options].sort((a, b) => a.cost.actualInCents - b.cost.actualInCents)

  let currentValue = ""
  if (destination.shippingSpeed) {
    currentValue = `speed::${ destination.shippingSpeed }`
  } else if (destination.shippingServiceCode) {
    currentValue = `code::${ destination.shippingServiceCode }`
  }

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      { saveError &&
        <div className="p-4 bg-red-300">Error saving shipping choice.</div>
      }

      <ul>
        {options.map(({ shippingSpeed, serviceCode, displayName, displayArrival, arrivalDate, cost }) => {
          const id = (shippingSpeed ? "speed::" + shippingSpeed : "code::" + serviceCode)
          return <li key={ id } className="mb-4">
            <label>
              <input
                type="radio"
                name="shippingOption"
                className="mr-2"
                onChange={ saveChange }
                checked={ currentValue === id || (!currentValue && cost.actualInCents === 0) }
                value={ id } />

              { displayName }
              { cost.actualInCents > 0 &&
                <span>
                  &nbsp;-&nbsp;
                  { cost.actualInCents < cost.listInCents ?
                  <>
                    <del>{ formatPrice(cost.listInCents) }</del>
                    <span>{ formatPrice(cost.actualInCents) }</span>
                  </> :
                  <>
                    <span>{ formatPrice(cost.actualInCents) }</span>
                  </>
                  }
                </span>
              }
              { displayArrival &&
                <div className="text-sm text-gray-600">Arrives { displayArrival }</div>
              }
            </label>
          </li>
        })}
      </ul>
    </form>
  )
}
