import React from 'react'
import { Link } from 'react-router-dom'

import type { DestinationFragment, OrderFragment } from "../graphql/__generated__"
import AddressSummary from './AddressSummary'
import QuantityForm from './QuantityForm'
import ShippingOptionsForm from './ShippingOptionsForm'
import ShippingChoice from './ShippingChoice'
import CoverImage from './CoverImage'
import PricingSummary from './PricingSummary'
import GiftMessage from './GiftMessage'
import useSteps, { CoverStep, MessageStep, AddressStep } from '../utils/Steps'
import type { Step } from '../utils/Steps'

type DestinationSummaryProps = {
  destination: DestinationFragment
  designId: string
  order: OrderFragment
  allowEdit: boolean
}

export default function DestinationSummary({ destination, designId, order, allowEdit }: DestinationSummaryProps) {
  const costs = order?.cost?.byDestination?.find(c => c.destinationId === destination.id)?.costs || null
  const { navigateTo } = useSteps()

  const navigate = (step: Step) => () => {
    navigateTo(step, {
      designId,
      destinationId: destination.id
    }, { returnHere: true })
  }

  return (
    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-12 md:col-span-3 mb-4 md:mb-0">
        <h3 className="font-bold inline-block mr-4">Cover</h3>
        { allowEdit && 
          <a 
            onClick={ navigate(CoverStep) }
            className="text-blue-500 cursor-pointer">Edit</a>
        }
        <CoverImage coverId={ destination.coverId || '' } />
        <div>
          { destination.extras?.find(extra => extra.id === 'giftbox-5in') ? <>+ gift box [$12]</> : null }
        </div>

        <h3 className="font-bold inline-block mt-4 mr-4">Gift Message</h3>
        { allowEdit &&
          <a 
            onClick={ navigate(MessageStep) }
            className="text-blue-500 cursor-pointer">Edit</a>
        }
        <GiftMessage message={ destination.giftMessage?.body } />
      </div>

      <div className="col-span-12 md:col-span-3 mb-4 md:mb-0">
        <h3 className="font-bold inline-block mr-4">Address</h3>
        { allowEdit &&
          <a 
            onClick={ navigate(AddressStep) }
            className="text-blue-500 cursor-pointer">Edit</a>
        }
        <AddressSummary address={ destination.address || null } />
      </div>

      <div className="col-span-12 md:col-span-3 mb-4 md:mb-0">
        <h3 className="font-bold">Shipping</h3>
        { allowEdit
          ? <ShippingOptionsForm destination={ destination } order={ order } />
          : <ShippingChoice destination={ destination } />
        }
      </div>

      <div className="col-span-12 md:col-span-3 mb-4 md:mb-0">
        <h3 className="font-bold">Quantity</h3>
        { allowEdit
          ? <QuantityForm destination={ destination } order={ order } designId={ designId } />
          : <p>Quantity: { destination.quantity }</p>
        }

        { costs?.total.listInCents !== 0 &&
          <>
            <h3 className="font-bold mt-8 md:mt-4">Subtotal</h3>
            <div className="max-w-sm">
              <PricingSummary costs={ costs } fields={ ["subtotal", "shipping"] }/>
            </div>
          </>
        }
      </div>
    </div>
  )
}
