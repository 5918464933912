import React from 'react'
import { Link } from 'react-router-dom'

import { formatDate } from '../utils/Date'
import type { OrderFragment, DesignWithDestinationsFragment } from '../graphql/__generated__'
import MediaStack from './MediaStack'

type OrderSummaryTileProps = {
  order: OrderFragment
}

function pluralize(singular: string, plural: string, qty: number): string {
  if (qty === 0) {
    return `no ${ plural } yet`
  } else if (qty === 1) {
    return `1 ${ singular }`
  } else {
    return `${ qty } ${ plural }`
  }
}

export default function OrderSummaryTile({ order }: OrderSummaryTileProps){
  const design = order?.designs && order.designs[0]
  let addressCount = order?.designs?.reduce((prevDesignCount: number, currentDesign: DesignWithDestinationsFragment) => {
    return prevDesignCount + (currentDesign.destinations?.length || 0)
  }, 0)

  return <>
    <div className="p-4 mr-2 h-full shadow bg-white rounded flex flex-col">
      <div className="text-sm whitespace-nowrap font-bold">
        Order created <time dateTime={ order.createdAt }>{ formatDate(order.createdAt) }</time>
      </div>

      <div className="text-sm my-2">
        { pluralize('book design', 'book designs', order.designs && order.designs.length || 0) } shipping to
        { ' ' + pluralize('address', 'addresses', addressCount || 0) }
      </div>

      <Link
        className="text-blue-400 underline flex flex-1 justify-end focus:outline-0 focus:font-bold text-pink-300"
        style={{ flexFlow: 'column' }}
        to={ `/order/${ order.id }` }>Open</Link>
    </div>
  </>
}
