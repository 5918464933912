import React from 'react'
import { useFormContext } from 'react-hook-form'

import type { StockFragment } from '../graphql/__generated__'
import type { CoverFormFields } from './GiftBoxFormInput'

type CoverFormInputProps = {
  coverId: string
  stock: StockFragment | undefined
}

export default function CoverFormInput({ coverId, stock }: CoverFormInputProps) {
  const { register } = useFormContext<CoverFormFields>();

  const book = stock?.books && stock?.books[0]
  const covers = book?.covers

  if (!covers) {
    return <></>
  }

  return <>

    <div className="text-gray-500">
      Select the cover you'd like your for your first video book. All books are set in
      hardcovers with a matte finish.
    </div>

    <ul className="grid grid-cols-2 md:grid-cols-4 gap-2">
      {
        covers.map((cover) => {
          return <li key={ cover.id }>
            { cover.images && cover.images[0] &&
              <>
                <input
                  {...register("coverId")}
                  id={`cover-${ cover.id }`}
                  type="radio" value={ cover.id }
                  className="invisible absolute peer" />

                <label
                  htmlFor={`cover-${ cover.id }`}
                  className="
                    block w-full h-48 bg-cover bg-center bg-no-repeat bg-clip-padding	cursor-pointer
                    outline outline-solid outline-transparent outline-2 shadow rounded
                    peer-checked:outline-pink-300 peer-checked:shadow-[0_0_10px_#fab1aa]"
                  style={{ backgroundImage: `url(${ cover.images[0] })` }}> </label>
              </>
            }
          </li>
        })
      }
    </ul>
  </>
}
