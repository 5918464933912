import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import type { ApolloError } from '@apollo/client'
import CircularProgress from '@mui/material/CircularProgress'

import { useQueryParams } from '../utils/Query'
import OrderEdit from '../components/OrderEdit'
import OrderStatus from '../components/OrderStatus'
import LoginModal from '../components/LoginModal'
import { OrderDocument } from '../graphql/__generated__'
import useSteps, { BuilderFlow, ReviewStep } from '../utils/Steps'

export default function OrderSummary() {
  const params = useQueryParams()
  const { orderId } = useParams()
  const { nextPath, currentStep, currentPath } = useSteps(
    BuilderFlow,
    ReviewStep,
    { orderId })
  const { data, loading, error } = useQuery(OrderDocument, {
    variables: {
      orderId,
    },
    onError: (err: ApolloError) => {
      console.error("Error loading order for order summary", err)
    },
  })
  const order = data?.orderStatus
  const thanks = params.get('thanks') === 'true'

  if (!orderId) {
    return <div>No order ID</div>
  }

  if (loading) {
    return <div className="flex justify-center"><CircularProgress /></div>
  }

  // The order state will automatically move to 'paid' when the
  // Stripe webhook completes. If this is marked as a thank you
  // page though, we know the user at least thinks they paid and
  // should show them the paid UI.
  const paid = order?.state !== 'draft' || thanks

  return <div className="md:max-w-[68rem]">
    <h1 className="text-xl mb-6">Your Order</h1>

    { error && <p className="p-2 m-4 bg-red-300">Error loading order.</p> }

    { !paid 
      ? <OrderEdit order={ order } />
      : <OrderStatus order={ order } showThanks={ thanks } />
    }
  
    <LoginModal />
  </div>
}
